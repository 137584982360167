/* eslint-disable react-hooks/exhaustive-deps */
import {
  Add,
  ArrowLeft,
  DeleteSharp,
  EditSharp,
  RemoveRedEye,
  UploadFile
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Page from "src/components/Page";
import { PollSelect, fetchNotificationList } from "src/utils/common";
import styled from "styled-components";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import { notification } from "src/utils/messages";
import {
  insertnotification,
  updateNotification,
  deleteNotification,
  getReadNotificationData
} from "src/api/commonapi";
import DataTable from "react-data-table-component";
import { Routes } from "../constant/api_url";
import Deletemodal from "./DeleteModal";
import Label from "../components/Label";
import { CSVLink } from "react-csv";

function Notification() {
  const readNotificationModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2
  };
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [notificationList, setNotificationList] = useState([]);
  const [newNotificationList, setNewNotificationList] = useState([]);
  const [response, setresponse] = useState(0);
  // 0- All, 1- insert, 2-update
  const [page, setPage] = useState(0);
  const [setfile, setFile] = useState({});
  const [fileimage, setfileimage] = useState();

  const handlePageChange = (newPageValue) => {
    setPage(newPageValue);
  };
  const [option, setOption] = useState(null);
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const NotificationFor = [
    { label: "All", value: -1 },
    { label: "LVB ", value: 0 },
    { label: "State", value: 1 },
    { label: "City", value: 2 },
    { label: "Chapter", value: 3 }
  ];
  const NotificationStatus = [
    { label: "All", value: 0 },
    { label: "Open ", value: 1 },
    { label: "Close", value: 2 }
  ];
  const NotificationType = [
    { label: "All", value: -1 },
    { label: "Photo ", value: 0 },
    { label: "Audio", value: 1 },
    { label: "Video", value: 2 },
    { label: "Question and Answer", value: 3 }
  ];
  const [filtereddata, setFilteredData] = useState({
    notificationFor: -1,
    notificationStatus: 0,
    notificationType: -1,
    search: "",
    startDate: "",
    endDate: ""
  });

  const getOptionsValueList = async (type = 0) => {
    let optionData = [];
    if (type != 0) {
      const resp = await PollSelect(type, loginUser?.u_type, loginUser?.city_id);
      resp?.map((e) => optionData.push({ value: e.value, label: e.name }));
      setOption([{ value: 0, label: "Select" }, ...optionData]);
    }
  };

  const getYoutubeVideoId = (url) => {
    if (!url) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? "https://www.youtube.com/embed/" + match[2] : null;
  };

  const [formData, setFormData] = useState({
    n_title: "",
    n_description: "",
    n_image: "",
    n_video: "",
    n_question: "",
    n_answer: "",
    // 0-Photo: "", 1-Audio: "", 2-Video: "", 3-Question and Answer
    n_type: "0",
    s_date: "",
    e_date: "",
    // 0-all, 1-LT, 2-members
    n_send_to: "0",
    // 0 - LVB , 1- State, 2- City, 3- Chapter
    n_for_type: "0",
    n_for_id: "",
    // status: 0-Inactive, 1-Active
    n_status: "1"
  });

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1
  });

  const getAcceptType = (type) => {
    switch (type) {
      case "0":
        return "image/*,.jpg,.jpeg,.png,.gif";
      case "1":
        return "audio/*,.mp3,.wav,.ogg";
      case 0:
        return "image/*,.jpg,.jpeg,.png,.gif";
      case 1:
        return "audio/*,.mp3,.wav,.ogg";
      default:
        return "";
    }
  };

  const resetFormData = () => {
    setFormData({
      n_title: "",
      n_description: "",
      n_image: "",
      n_audio: "",
      n_video: "",
      n_question: "",
      n_answer: "",
      n_type: "0",
      s_date: "",
      e_date: "",
      n_send_to: "0",
      n_for_type: "0",
      n_for_id: "",
      n_status: "1"
    });
  };

  const handleFormSubmit = async (newData = undefined) => {
    try {
      let fData = new FormData();

      if (formData != undefined && formData.n_id != undefined) {
        // Update the form data
        if (formData.n_type == 0 || formData.n_type == 1) {
          fData.append("n_title", formData.n_title);
          fData.append("n_description", formData.n_description);
          fData.append("n_image", setfile.file);
          fData.append("n_type", formData.n_type);
          fData.append("s_date", formData.s_date);
          fData.append("e_date", formData.e_date);
          fData.append("n_send_to", formData.n_send_to);
          fData.append("n_for_type", formData.n_for_type);
          fData.append("n_for_id", formData.n_for_id);
          fData.append("n_status", formData.n_status);
          fData.append("n_id", formData?.n_id);
          const resp = await updateNotification(fData);
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
          resetFormData();
          setPage(0);
          getNotificationList();
          setfileimage();
        } else if (formData.n_type == 2) {
          fData.append("n_title", formData.n_title);
          fData.append("n_description", formData.n_description);
          fData.append("n_video", formData.n_video);
          fData.append("n_type", formData.n_type);
          fData.append("s_date", formData.s_date);
          fData.append("e_date", formData.e_date);
          fData.append("n_send_to", formData.n_send_to);
          fData.append("n_for_type", formData.n_for_type);
          fData.append("n_for_id", formData.n_for_id);
          fData.append("n_status", formData.n_status);
          fData.append("n_id", formData?.n_id);
          const resp = await updateNotification(fData);
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
          resetFormData();
          setPage(0);
          getNotificationList();
        } else if (formData.n_type == 3) {
          fData.append("n_question", formData.n_question);
          fData.append("n_answer", formData.n_answer);
          fData.append("n_type", formData.n_type);
          fData.append("s_date", formData.s_date);
          fData.append("e_date", formData.e_date);
          fData.append("n_send_to", formData.n_send_to);
          fData.append("n_for_type", formData.n_for_type);
          fData.append("n_for_id", formData.n_for_id);
          fData.append("n_status", formData.n_status);
          fData.append("n_id", formData?.n_id);
          const resp = await updateNotification(fData);
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
          resetFormData();
          setPage(0);
          getNotificationList();
        } else {
          let notify = notification({
            type: "error",
            message: "something went wrong please try again later"
          });
          notify();
        }
      } else {
        // Insert the form data
        if (formData.n_type == 0 || formData.n_type == 1) {
          if (
            setfile == "" ||
            setfile == undefined ||
            formData.n_title == "" ||
            formData.n_description == "" ||
            formData.s_date == "" ||
            formData.e_date == "" ||
            formData.n_send_to == "" ||
            formData.n_for_type == "" ||
            (formData.n_for_type != 0 && formData.n_for_id == "")
          ) {
            let notify = notification({
              type: "error",
              message: "Please enter all required fields to submit"
            });
            notify();
          } else {
            // API Call
            fData.append("n_title", formData.n_title);
            fData.append("n_description", formData.n_description);
            fData.append("n_image", setfile.file);
            fData.append("n_type", formData.n_type);
            fData.append("s_date", formData.s_date);
            fData.append("e_date", formData.e_date);
            fData.append("n_send_to", formData.n_send_to);
            fData.append("n_for_type", formData.n_for_type);
            fData.append("n_for_id", formData.n_for_id);
            fData.append("n_status", formData.n_status);
            const resp = await insertnotification(fData);
            let notify = notification({
              type: resp.status == 0 ? "error" : "success",
              message: resp.message
            });
            notify();
            resetFormData();
            setPage(0);
            getNotificationList();
            setfileimage();
          }
        } else if (formData.n_type == 2) {
          if (
            formData.n_video == "" ||
            formData.n_title == "" ||
            formData.n_description == "" ||
            formData.s_date == "" ||
            formData.e_date == "" ||
            formData.n_send_to == "" ||
            formData.n_for_type == "" ||
            (formData.n_for_type != 0 && formData.n_for_id == "") ||
            (formData.n_video != "" && getYoutubeVideoId(formData?.n_video) == null)
          ) {
            let notify = notification({
              type: "error",
              message: "Please enter all required fields to submit"
            });
            notify();
          } else {
            fData.append("n_title", formData.n_title);
            fData.append("n_description", formData.n_description);
            fData.append("n_video", formData.n_video);
            fData.append("n_type", formData.n_type);
            fData.append("s_date", formData.s_date);
            fData.append("e_date", formData.e_date);
            fData.append("n_send_to", formData.n_send_to);
            fData.append("n_for_type", formData.n_for_type);
            fData.append("n_for_id", formData.n_for_id);
            fData.append("n_status", formData.n_status);
            const resp = await insertnotification(fData);
            let notify = notification({
              type: resp.status == 0 ? "error" : "success",
              message: resp.message
            });
            notify();
            resetFormData();
            setPage(0);
            getNotificationList();
          }
        } else if (formData.n_type == 3) {
          if (
            formData.n_question == "" ||
            formData.n_answer == "" ||
            formData.s_date == "" ||
            formData.e_date == "" ||
            formData.n_send_to == "" ||
            formData.n_for_type == "" ||
            (formData.n_for_type != 0 && formData.n_for_id == "")
          ) {
            let notify = notification({
              type: "error",
              message: "Please enter all required fields to submit"
            });
            notify();
          } else {
            // API Call
            fData.append("n_question", formData.n_question);
            fData.append("n_answer", formData.n_answer);
            fData.append("n_type", formData.n_type);
            fData.append("s_date", formData.s_date);
            fData.append("e_date", formData.e_date);
            fData.append("n_send_to", formData.n_send_to);
            fData.append("n_for_type", formData.n_for_type);
            fData.append("n_for_id", formData.n_for_id);
            fData.append("n_status", formData.n_status);
            const resp = await insertnotification(fData);
            let notify = notification({
              type: resp.status == 0 ? "error" : "success",
              message: resp.message
            });
            notify();
            resetFormData();
            setPage(0);
            getNotificationList();
          }
        } else {
          let notify = notification({
            type: "error",
            message: "something went wrong please try again later"
          });
          notify();
        }
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const notificationdelete = async (n_id) => {
    const resp = await deleteNotification(n_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message
    });
    notify();

    if (resp.status == 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
  };

  const getNotificationList = async () => {
    setNotificationList(await fetchNotificationList(loginUser.city_id, loginUser.u_type));
  };

  useEffect(async () => {
    setNotificationList(await fetchNotificationList(loginUser.city_id, loginUser.u_type));
  }, [response]);

  const [readNotificationDataModal, setReadNotificationDataModal] = useState(false);
  const [readNotificationList, setReadNotificationList] = useState([]);

  // Open modal with notification ID
  const openModalWithNotification = async (n_id) => {
    setReadNotificationDataModal(true); // Open modal

    try {
      const resp = await getReadNotificationData(n_id);
      if (resp.status === 1) {
        setReadNotificationList(resp.data);
      } else {
        setReadNotificationList([]);
      }
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };

  // Close modal and reset state
  const closeModal = () => {
    setReadNotificationDataModal(false);
    setReadNotificationList([]);
  };

  const readNotificationDetailColumns = [
    {
      name: "Member Name",
      selector: (row) => row?.m_name,
      sortable: true
    },
    {
      name: "Notification Read Date",
      selector: (row) => row?.r_date,
      sortable: true
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <Label
            variant="ghost"
            color="success"
            style={{
              marginBottom: "10px",
              display: "flex",
              fontSize: "16px"
            }}
          >
            Read
          </Label>
        </>
      ),
      sortable: true
    }
  ];

  const export_readNotificationDetailColumns = [
    { label: "Member Name", key: "m_name" },
    { label: "Notification Read Date", key: "r_date" }
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.n_id,
      sortable: true,
      width: "50px"
    },
    {
      name: "Type",
      wrap: true,
      width: "90px",
      selector: (row) =>
        row.n_type == 1
          ? "Audio"
          : row.n_type == 2
          ? "Video"
          : row.n_type == 3
          ? "Question and Answer"
          : "Photo"
    },
    {
      name: "Image/Audio/Video",
      wrap: true,
      width: "150px",
      cell: (row) => {
        if (row.n_type === 1) {
          return (
            <audio controls src={`${Routes.API_HOSTNAME}images/notification/${row.n_image}`} />
          );
        } else if (row.n_type === 0) {
          return (
            <img
              src={`${Routes.API_HOSTNAME}images/notification/${row.n_image}`}
              alt="img"
              width="100"
            />
          );
        } else if (row.n_type === 2) {
          return row.n_video;
        } else {
          return "-";
        }
      }
    },
    {
      name: "Title",
      wrap: true,
      width: "120px",
      selector: (row) => (row.n_title != null && row.n_title != "" ? row.n_title : "-"),
      sortable: true
    },
    {
      name: "Description",
      wrap: true,
      width: "120px",
      selector: (row) =>
        row.n_description != null && row.n_description != "" ? row.n_description : "-",
      sortable: true
    },
    {
      name: "Question",
      wrap: true,
      width: "120px",
      selector: (row) => ((row.n_question != null) & (row.n_question != "") ? row.n_question : "-"),
      sortable: true
    },
    {
      name: "Answer",
      wrap: true,
      width: "120px",
      selector: (row) => (row.n_answer != null && row.n_answer != "" ? row.n_answer : "-"),
      sortable: true
    },
    {
      name: "Notification Send To",
      width: "90px",

      wrap: true,
      selector: (row) =>
        row.n_send_to == 1
          ? "LT"
          : row.n_send_to == 2
          ? "Members"
          : "All"
    },
    {
      name: "Notification For",
      width: "80px",

      wrap: true,
      selector: (row) =>
        row.n_for_type == 1
          ? "State"
          : row.n_for_type == 2
          ? "City"
          : row.n_for_type == 3
          ? "Chapter"
          : "LVB"
    },
    {
      name: "Location",
      wrap: true,
      width: "100px",

      selector: (row) =>
        loginUser.u_type != 0
          ? row.city_name
          : row.n_for_type == 1
          ? row.s_name
          : row.n_for_type == 2
          ? row.city_name
          : row.n_for_type == 3
          ? row.ch_name
          : "LVB"
    },
    {
      name: "Start Date",
      wrap: true,
      width: "auto",
      selector: (row) => row.s_date,
      sortable: true
    },
    {
      name: "End Date",
      selector: (row) => row.e_date,
      wrap: true,
      width: "auto",
      sortable: true
    },

    {
      name: "Action",
      wrap: true,
      width: "150px",
      cell: (row) => (
        <>
          <button
            onClick={() => {
              setDvisible({ mval: true, id: row.n_id });
            }}
            style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}
          >
            <Tooltip title="Delete">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </button>
          <button
            onClick={() => {
              setPage(2);
              setFormData(row);
              getOptionsValueList(row.n_for_type);
              setfileimage();
            }}
            style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}
          >
            <Tooltip title="Edit">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </button>
          <button
            onClick={() => openModalWithNotification(row.n_id)}
            style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}
          >
            <Tooltip title="See Assign Member List">
              <IconButton>
                <RemoveRedEye color="success" />
              </IconButton>
            </Tooltip>
          </button>
        </>
      )
    }
  ];

  const filterData = () => {
    const filterByType = (notification) => {
      return (
        filtereddata.notificationFor == -1 ||
        notification.n_for_type === filtereddata.notificationFor
      );
    };

    const filterByNotificationType = (notification) => {
      return (
        filtereddata.notificationType == -1 || notification.n_type === filtereddata.notificationType
      );
    };

    const filterByOpenClose = (notification) => {
      if (filtereddata.notificationStatus === 1) {
        // Open notifications: future notifications only
        return moment(notification.e_date, "DD-MM-YYYY").isSameOrAfter(moment(), "day");
      }
      if (filtereddata.notificationStatus === 2) {
        // Closed notifications: past notifications only
        return moment(notification.e_date, "DD-MM-YYYY").isBefore(moment(), "day");
      }
      return true;
    };

    const filterBySearchType = (notification) => {
      let searchFilterText = filtereddata.search.toLowerCase();
      return (
        filtereddata.search == "" ||
        notification?.n_question?.toLowerCase().includes(searchFilterText) ||
        notification?.n_answer?.toLowerCase().includes(searchFilterText) ||
        notification?.n_title?.toLowerCase().includes(searchFilterText) ||
        notification?.n_description?.toLowerCase().includes(searchFilterText)
      );
    };

    const nNotification = notificationList.filter(
      (notification) =>
        filterByType(notification) &&
        filterByNotificationType(notification) &&
        filterByOpenClose(notification) &&
        filterBySearchType(notification)
    );

    setNewNotificationList(nNotification);
  };

  useEffect(() => {
    filterData();
  }, [filtereddata, notificationList]);

  return (
    <>
      <Deletemodal visible={dvisible} setVisible={setDvisible} deletefunc={notificationdelete} />

      <Page title="Notification | Local Vocal Business Group">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" gutterBottom className="mb-0">
              Notification
            </Typography>
            {page === 0 ? (
              <Button variant="contained" color="primary" onClick={() => handlePageChange(1)}>
                <Add sx={{ fontSize: "20px", marginRight: "5px" }} />
                Add Notification
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  resetFormData();
                  setPage(0);
                  setfileimage();
                }}
              >
                <ArrowLeft sx={{ fontSize: "20px", marginRight: "5px" }} />
                Back
              </Button>
            )}
          </Stack>

          {page != 0 ? (
            <>
              <Card style={{ padding: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className=""
                      style={{ textAlign: "center" }}
                    >
                      {page === 1 ? "Add New Notification" : "Edit Notification"}
                    </Typography>
                    <hr style={{ marginBottom: "0", marginTop: "0" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel> Select Type</FormLabel>
                    <Stack spacing={3}>
                      <RadioGroup
                        row
                        defaultValue={0}
                        value={formData?.n_type}
                        onChange={(e) => {
                          if (e.target.value == 3) {
                            setFormData({
                              ...formData,
                              n_type: e.target.value,
                              n_title: "",
                              n_description: "",
                              n_image: "",
                              n_audio: "",
                              n_video: "",
                              n_question: "",
                              n_answer: ""
                            });
                          } else {
                            setFormData({
                              ...formData,
                              n_type: e.target.value
                            });
                          }
                        }}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="n_type"
                      >
                        <FormControlLabel value={0} control={<Radio />} label="Photo" />
                        <FormControlLabel value={1} control={<Radio />} label="Audio" />
                        <FormControlLabel value={2} control={<Radio />} label="Video" />
                        <FormControlLabel
                          value={3}
                          control={<Radio />}
                          label="Question and Answer"
                        />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>Notification Send To</FormLabel>
                    <Stack spacing={3}>
                      <RadioGroup
                        style={{ marginTop: "0px" }}
                        row
                        defaultValue={0}
                        value={formData?.n_send_to}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            n_send_to: e.target.value
                          });
                        }}
                        name="n_send_to"
                      >
                        <FormControlLabel value="0" control={<Radio />} label="All" />
                        <FormControlLabel value="1" control={<Radio />} label="LT" />
                        <FormControlLabel value="2" control={<Radio />} label="Members" />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel id="demo-radio-buttons-group-label">Notification For</FormLabel>
                    <Stack spacing={3}>
                      <RadioGroup
                        style={{ marginTop: "0px" }}
                        row
                        defaultValue={0}
                        value={formData?.n_for_type}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            n_for_type: e.target.value,
                            n_for_id: ""
                          });
                          getOptionsValueList(e.target.value);
                        }}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="n_for_type"
                      >
                        {loginUser?.u_type == 0 && (
                          <>
                            <FormControlLabel value="0" control={<Radio />} label="LVB" />
                            <FormControlLabel value="1" control={<Radio />} label="State" />
                          </>
                        )}
                        <FormControlLabel value="2" control={<Radio />} label="City" />
                        <FormControlLabel value="3" control={<Radio />} label="Chapter" />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                  {formData.n_for_type != 0 ? (
                    <Grid item xs={12}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select
                          {formData?.n_for_type == 1
                            ? " State"
                            : formData?.n_for_type == 2
                            ? " City"
                            : formData?.n_for_type == 3
                            ? " Chapter"
                            : " LVB"}
                        </label>
                        <Select
                          required={true}
                          error={true}
                          style={{ marginTop: "0px" }}
                          value={
                            option != null &&
                            option.map((c) => {
                              if (c.value == formData?.n_for_id) {
                                return { value: c.value, label: c.label };
                              }
                            })
                          }
                          onChange={(e) => {
                            setFormData({ ...formData, n_for_id: e.value });
                          }}
                          menuPortalTarget={document.body}
                          options={option}
                        />
                      </Stack>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {
                    // display Title and description if selected type is not Question and Answer
                    formData?.n_type != 3 && (
                      <>
                        <Grid item xs={12}>
                          <Stack spacing={3}>
                            <TextField
                              required
                              type="text"
                              label="Title"
                              fullWidth
                              value={formData?.n_title}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  n_title: e.target.value
                                });
                              }}
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            label="Description"
                            required
                            multiline
                            fullWidth
                            rows={4}
                            value={formData?.n_description}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                n_description: e.target.value
                              });
                            }}
                          />
                        </Grid>
                      </>
                    )
                  }
                  {(formData?.n_type == 0 || formData?.n_type == 1) && (
                    <Grid item xs={12}>
                      <Button
                        component="label"
                        variant="contained"
                        color="primary"
                        tabIndex={-1}
                        sx={{ textTransform: "none", p: "10px" }}
                        startIcon={<UploadFile />}
                      >
                        Click to Upload file
                        <VisuallyHiddenInput
                          type="file"
                          required
                          multiple={false}
                          accept={getAcceptType(formData?.n_type)}
                          onChange={(event) => {
                            const uploadedFile = event.currentTarget.files[0];

                            // Check if file exists and validate its size
                            if (uploadedFile && uploadedFile.size > 2097152) {
                              let notify = notification({
                                type: "error",
                                message: "File size exceeds 2MB. Please upload a smaller file."
                              });
                              notify();
                              return;
                            }

                            // If valid, update the state
                            setFile({ file: uploadedFile });
                            setfileimage(URL.createObjectURL(uploadedFile));
                          }}
                        />
                      </Button>

                      {/* Display uploaded Image / Audio */}
                      {page == 1 &&
                        (fileimage !== undefined ? (
                          <div style={{ marginTop: "10px" }}>
                            {formData?.n_type == 0 ? (
                              <>
                                <h4>Image Preview</h4>
                                <div className="position-relative">
                                  <img
                                    src={fileimage}
                                    alt="Uploaded file preview"
                                    style={{
                                      width: "450px",
                                      objectFit: "contain",
                                      height: "250px",
                                      boxShadow: "0 0 5px #ccc",
                                      borderRadius: "5px"
                                    }}
                                  />
                                </div>
                              </>
                            ) : formData?.n_type == 1 ? (
                              <>
                                <h4>Audio Preview</h4>
                                <audio controls src={fileimage} />
                              </>
                            ) : null}
                          </div>
                        ) : (
                          ""
                        ))}
                      {page == 2 &&
                        (fileimage !== undefined ? (
                          <div style={{ marginTop: "10px" }}>
                            {formData?.n_type == 0 ? (
                              <>
                                <h4>Image Preview</h4>
                                <div className="position-relative">
                                  <img
                                    src={fileimage}
                                    alt="Uploaded file preview"
                                    style={{
                                      width: "450px",
                                      objectFit: "contain",
                                      height: "250px",
                                      boxShadow: "0 0 5px #ccc",
                                      borderRadius: "5px"
                                    }}
                                  />
                                </div>
                              </>
                            ) : formData?.n_type == 1 ? (
                              <>
                                <h4>Audio Preview</h4>
                                <audio controls src={fileimage} />
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div style={{ marginTop: "10px" }}>
                            {formData?.n_type == 0 ? (
                              <>
                                <h4>Image Preview</h4>
                                <div className="position-relative">
                                  <img
                                    src={`${Routes.API_HOSTNAME}images/notification/${formData?.n_image}`}
                                    alt="Uploaded file preview"
                                    style={{
                                      width: "450px",
                                      objectFit: "contain",
                                      height: "250px",
                                      boxShadow: "0 0 5px #ccc",
                                      borderRadius: "5px"
                                    }}
                                  />
                                </div>
                              </>
                            ) : formData?.n_type == 1 ? (
                              <>
                                <h4>Audio Preview</h4>
                                <audio
                                  controls
                                  src={`${Routes.API_HOSTNAME}images/notification/${formData?.n_image}`}
                                />
                              </>
                            ) : null}
                          </div>
                        ))}
                    </Grid>
                  )}
                  {formData?.n_type == 2 && (
                    <>
                      <Grid item xs={12}>
                        <Stack spacing={3}>
                          <TextField
                            required
                            type="text"
                            label="Video URL"
                            fullWidth
                            value={formData?.n_video}
                            onChange={(e) => {
                              setFormData({ ...formData, n_video: e.target.value });
                            }}
                          />
                        </Stack>
                      </Grid>
                      {/* Display Youtube URL */}
                      {formData?.n_video != "" && getYoutubeVideoId(formData?.n_video) != null && (
                        <Grid item xs={12}>
                          <div
                            className="rounded"
                            style={{
                              position: "relative",
                              height: "250px",
                              overflow: "hidden",
                              maxWidth: "100%"
                            }}
                          >
                            <p>hello</p>
                            <iframe
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "450px",
                                height: "250px"
                              }}
                              src={getYoutubeVideoId(formData?.n_video)}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            />
                          </div>
                        </Grid>
                      )}
                    </>
                  )}
                  {/* Display Question and Answer */}
                  {formData?.n_type == 3 && (
                    <>
                      <Grid item xs={12}>
                        <Stack spacing={3}>
                          <TextField
                            required
                            type="text"
                            label="Question"
                            fullWidth
                            value={formData?.n_question}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                n_question: e.target.value
                              });
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          required
                          type="text"
                          label="Answer"
                          fullWidth
                          value={formData?.n_answer}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              n_answer: e.target.value
                            });
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  {/* grid 6 add start date and end date */}
                  <Grid item xs={6}>
                    <Stack spacing={3}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        style={{ marginTop: "15px" }}
                      >
                        <DatePicker
                          type="date"
                          name="s_date"
                          label="Start Date "
                          inputFormat="dd/MM/yyyy"
                          value={
                            formData.s_date != "" ? moment(formData.s_date, "DD-MM-YYYY") : null
                          }
                          onChange={(newDate) => {
                            setFormData({
                              ...formData,
                              s_date: moment(newDate, "DD-MM-YYYY").format("DD-MM-YYYY")
                            });
                          }}
                          renderInput={(params) => <TextField {...params} required />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={3}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        style={{ marginTop: "15px" }}
                      >
                        <DatePicker
                          type="date"
                          name="e_date"
                          label="End Date "
                          inputFormat="dd/MM/yyyy"
                          value={
                            formData.e_date != "" ? moment(formData.e_date, "DD-MM-YYYY") : null
                          }
                          onChange={(newDate) => {
                            setFormData({
                              ...formData,
                              e_date: moment(newDate, "DD-MM-YYYY").format("DD-MM-YYYY")
                            });
                          }}
                          renderInput={(params) => <TextField {...params} required />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>

                  {/* submit and rest form state */}
                  <Grid item xs={12} spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ marginRight: "5px" }}
                      onClick={() => handleFormSubmit()} // handleFormSubmit function
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        resetFormData();
                        setPage(0);
                        setfileimage();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </>
          ) : (
            <>
              <Card className="p-20 mb-15">
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={2}>
                    <Stack sx={{ my: "5px" }}>
                      <label>Notification For</label>
                      <Select
                        menuPortalTarget={document.body}
                        value={NotificationFor.map((c) => {
                          if (c.value == filtereddata.notificationFor) {
                            return { value: c.value, label: c.label };
                          }
                        })}
                        onChange={(e) => {
                          setFilteredData({
                            ...filtereddata,
                            notificationFor: e.value
                          });
                        }}
                        options={NotificationFor}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={2}>
                    <Stack>
                      <label>Notification Status</label>
                      <Select
                        menuPortalTarget={document.body}
                        value={NotificationStatus.map((c) => {
                          if (c.value == filtereddata.notificationStatus) {
                            return { value: c.value, label: c.label };
                          }
                        })}
                        onChange={(e) => {
                          setFilteredData({
                            ...filtereddata,
                            notificationStatus: e.value
                          });
                        }}
                        options={NotificationStatus}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={3}>
                    <Stack>
                      <label>Notification Type</label>
                      <Select
                        menuPortalTarget={document.body}
                        value={NotificationType.map((c) => {
                          if (c.value == filtereddata.notificationType) {
                            return { value: c.value, label: c.label };
                          }
                        })}
                        onChange={(e) => {
                          setFilteredData({
                            ...filtereddata,
                            notificationType: e.value
                          });
                        }}
                        options={NotificationType}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={4}>
                    <Stack spacing={10}>
                      <TextField
                        style={{ marginTop: "0px" }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Search"
                        name="name"
                        autoCorrect="off"
                        value={filtereddata.search}
                        onChange={(e) => {
                          setFilteredData({
                            ...filtereddata,
                            search: e.target.value
                          });
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end"
                    }}
                  >
                    <Button
                      onClick={async () => {
                        setFilteredData({
                          notificationFor: -1,
                          notificationStatus: 0,
                          notificationType: -1,
                          search: "",
                          startDate: "",
                          endDate: ""
                        });
                      }}
                      variant="contained"
                    >
                      clear
                    </Button>
                  </Grid>
                </Grid>
              </Card>
              <Card className="p-2" style={{ padding: "15px" }}>
                {notificationList && (
                  <>
                    <DataTable
                      filter
                      pagination
                      highlightOnHover
                      columns={columns}
                      data={newNotificationList != null && newNotificationList}
                    />
                  </>
                )}
              </Card>
            </>
          )}

          <Modal
            open={readNotificationDataModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={readNotificationModalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                Notification Read By Members
              </Typography>
              <hr />

              <Stack style={{ marginBottom: "10px", marginTop: "10px" }}>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={2}>
                    <Button className="btn btn-export">
                      <CSVLink
                        data={readNotificationList}
                        headers={export_readNotificationDetailColumns}
                        filename={`Notification Read Date.csv`}
                        className="btn btn-primary test"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    </Button>
                  </Grid>
                </Grid>
                <Card>
                  <DataTable
                    filter
                    fixedHeaderScrollHeight="450px"
                    fixedHeader={true}
                    pagination
                    highlightOnHover
                    columns={readNotificationDetailColumns}
                    data={readNotificationList}
                  />
                </Card>
              </Stack>
              <hr />
              <div
                style={{
                  margin: "10px  auto 0",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  variant="outlined"
                  onClick={closeModal}
                  style={{ marginTop: "0px", marginRight: "0px" }}
                >
                  Close
                </Button>
              </div>
            </Box>
          </Modal>
        </Container>
      </Page>
    </>
  );
}

export default Notification;
