import { DeleteSharp, EditSharp } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  deleteMeetingFeedbackFor,
  getMeetingFeedbackForList,
  insertMeetingFeedbackFor,
  updateMeetingFeedbackFor
} from "src/api/commonapi";
import Page from "src/components/Page";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";

export default function MeetingFeedbackFor() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2
  };

  const [addEditModal, setAddEditModal] = useState(false);
  const [meetingfeedbackforData, setMeetingfeedbackforData] = useState({
    meeting_feedback_for: ""
  });
  const [tableData, setTableData] = useState([]);
  const toggleAddEditModal = () => setAddEditModal(!addEditModal);
  const resetFormData = () => {
    setMeetingfeedbackforData({
      meeting_feedback_for: ""
    });
  };
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [response, setresponse] = useState(0);

  const handleSubmit = async (newData = undefined) => {
    if (newData != undefined ? newData : meetingfeedbackforData.m_f_id != undefined) {
      // update
      if ((newData != undefined ? newData : meetingfeedbackforData).meeting_feedback_for != "") {
        let fnData = new FormData();
        fnData.append(
          "m_f_id",
          newData != undefined ? newData.m_f_id : meetingfeedbackforData.m_f_id
        );
        fnData.append(
          "meeting_feedback_for",
          newData != undefined
            ? newData.meeting_feedback_for
            : meetingfeedbackforData.meeting_feedback_for
        );

        let resp = await updateMeetingFeedbackFor(fnData);
        if (resp.status != 0) {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
          resetFormData();
          newData == undefined && toggleAddEditModal();
          getMeetingFeedbackForData();
        } else {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
        }
      } else {
        let notify = notification({
          type: "error",
          message: "Please Fill All Fields data"
        });
        notify();
      }
    } else {
      // Insert
      if (meetingfeedbackforData.meeting_feedback_for != "") {
        let fnData = new FormData();
        fnData.append("meeting_feedback_for", meetingfeedbackforData.meeting_feedback_for);
        let resp = await insertMeetingFeedbackFor(fnData);
        if (resp.status != 0) {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
          resetFormData();
          toggleAddEditModal();
          getMeetingFeedbackForData();
        } else {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
        }
      } else {
        let notify = notification({
          type: "error",
          message: "Please Fill All Fields data"
        });
        notify();
      }
    }
  };
  const deletemeetingfeedbackfor = async (m_f_id) => {
    const resp = await deleteMeetingFeedbackFor(m_f_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message
    });
    notify();

    if (resp.status == 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
  };
  const getMeetingFeedbackForData = async () => {
    let res = await getMeetingFeedbackForList();
    setTableData(res);
  };
  useEffect(() => {
    getMeetingFeedbackForData();
  }, [response]);

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row?.m_f_id,
      sortable: true,
      width: "70px"
    },
    {
      name: "Name",
      wrap: true,
      selector: (row) => row?.meeting_feedback_for,
      sortable: true
    },
    {
      name: "Action",
      wrap: true,
      sortable: true,
      selector: (row) => (
        <>
          <Tooltip title="Edit Pin">
            <IconButton
              onClick={() => {
                setMeetingfeedbackforData({
                  meeting_feedback_for: row.meeting_feedback_for,
                  m_f_id: row.m_f_id
                });
                toggleAddEditModal();
              }}
            >
              <EditSharp color="warning" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDvisible({ mval: true, id: row.m_f_id });
              }}
            >
              <DeleteSharp color="error" />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ];

  return (
    <>
      <Deletemodal visible={dvisible} setVisible={setDvisible} deletefunc={deletemeetingfeedbackfor} />

      <Page title="LVB Pin Badge | Local Vocal Business Group">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h4" gutterBottom>
                  Meeting Feedback For
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => (toggleAddEditModal(), resetFormData())}
                style={{ marginBottom: "15px", float: "right" }}
                variant="contained"
              >
                Add Meeting Feedback For
              </Button>
            </Grid>
          </Grid>

          <Modal
            open={addEditModal}
            onClose={() => (toggleAddEditModal(), resetFormData())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                Add Meeting Feedback For
              </Typography>
              <hr />
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <Grid container>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      style={{ marginTop: "15px" }}
                      height={10}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      label="Meeting Feedback For"
                      name="meeting_feedback_for"
                      placeholder="Enter Meeting Feedback For"
                      onChange={(e) => {
                        setMeetingfeedbackforData({
                          ...meetingfeedbackforData,
                          meeting_feedback_for: e.target.value
                        });
                      }}
                      value={meetingfeedbackforData?.meeting_feedback_for}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => (toggleAddEditModal(), resetFormData())}
                      style={{ marginTop: "15px", marginRight: "15px" }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginTop: "15px" }}
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Modal>

          <Card spacing={3} style={{ padding: "10px", marginTop: "20px" }}>
            <DataTable
              filter
              pagination
              highlightOnHover
              columns={columns}
              data={tableData != null ? tableData : []}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
