import { DeleteSharp, EditSharp, PictureAsPdf } from "@mui/icons-material";
import {
  Button,
  Card,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { Container } from "react-trello";
import { insertmagazine, magazineDelete, updateMagazine } from "src/api/commonapi";
import Page from "src/components/Page";
import { Routes } from "src/constant/api_url";
import { fetchMagazineList, PollSelect } from "src/utils/common";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { LoadingButton } from "@mui/lab";

export default function Magazine() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [step, setStep] = useState(0);
  const [getdata, setgetdata] = useState({ m_type: loginUser?.u_type === 0 ? 0 : 2 });
  const [option, setOption] = useState(null);
  //   const [setfile, setFile] = useState({});
  const [setfile, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState();

  const [getmagazinelist, setmagazinelist] = useState([]);
  const [response, setresponse] = useState(0);
  const [newMagazinelist, setnewMagazinelist] = useState([]);
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const EventType = [
    { label: "All", value: -1 },
    { label: "LVB ", value: 0 },
    { label: "State", value: 1 },
    { label: "City", value: 2 },
    { label: "Chapter", value: 3 }
  ];
  const [filtereddata, setFilteredData] = useState({
    eventType: -1
  });

  const onChangeFunction = (e) => {
    if (e.target.name === "m_type") {
      setOption([]);
      setSelectData(e.target.value);
    }
    setgetdata({
      ...getdata,
      [e.target.name]: e.target.value
    });
  };

  useEffect(async () => {
    if (loginUser?.u_type === 1) {
      setSelectData(2);
    }
  }, []);

  const setSelectData = async (type = 0) => {
    let optiondata = [];
    if (type != 0) {
      const resp = await PollSelect(type, loginUser?.u_type, loginUser?.city_id);
      resp?.map((e) => optiondata.push({ value: e.value, label: e.name }));
      setOption([{ value: 0, label: "Select" }, ...optiondata]);
    }
  };

  const handleSubmit = async () => {
    let fdata = new FormData();
    fdata.append("m_type", getdata?.m_type);
    fdata.append("m_type_id", getdata?.m_type == 0 ? 0 : getdata?.m_type_id);
    fdata.append("document", setfile?.file || null);

    setIsFormSubmit(true);
    if (step === 1) {
      if (getdata.m_type === 0 && (!setfile?.file || setfile.file === undefined)) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields"
        });
        notify();
        setIsFormSubmit(false);
      } else if (
        (getdata.m_type !== 0 && (getdata.m_type_id === 0 || getdata.m_type_id === undefined)) ||
        !setfile?.file ||
        setfile.file === undefined
      ) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields"
        });
        notify();
        setIsFormSubmit(false);
      } else {
        const resp = await insertmagazine(fdata);

        let notify = notification({
          type: resp.status === 0 ? "error" : "success",
          message: resp.message
        });
        notify();
        setgetdata({
          m_type: loginUser?.u_type === 0 ? 0 : 2
        });
        setIsFormSubmit(false);
        setFile(null);
        setFileUrl("");
        setStep(0);
        getlandmarklist();
      }
    } else {
      if (!getdata?.magazine_id) {
        let notify = notification({
          type: "error",
          message: "Magazine ID is required for update"
        });
        notify();
        setIsFormSubmit(false);
        return;
      }
      if (!getdata?.m_type_id && getdata?.m_type !== 0) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields"
        });
        notify();
        setIsFormSubmit(false);
        return;
      }

      fdata.append("magazine_id", getdata?.magazine_id);
      const resp = await updateMagazine(fdata);

      let notify = notification({
        type: resp.status === 0 ? "error" : "success",
        message: resp.message
      });
      notify();
      setgetdata({
        m_type: loginUser?.u_type === 0 ? 0 : 2
      });
      setFile(null);
      setIsFormSubmit(false);
      setFileUrl("");
      setStep(0);
      getlandmarklist();
    }
  };

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row?.magazine_id,
      sortable: true,
      width: "70px"
    },
    {
      name: "Document",
      selector: (row) => {
        const originalName = row?.document
          ? row.document.split("_").slice(1).join("_")
          : row?.document;

        return (
          <a
            href={`${Routes.API_HOSTNAME}images/magazine/${row?.document}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <PictureAsPdf style={{ color: "red" }} />
            {originalName}
          </a>
        );
      },
      sortable: false
    },
    {
      name: "Magazine Type",
      wrap: true,
      selector: (row) =>
        row.m_type === 1
          ? "State"
          : row.m_type === 2
          ? "City"
          : row.m_type === 3
          ? "Chapter"
          : "LVB"
    },
    {
      name: "Location",
      wrap: true,
      selector: (row) =>
        loginUser.u_type !== 0
          ? row.city_name
          : row.m_type === 1
          ? row.s_name
          : row.m_type === 2
          ? row.city_name
          : row.m_type === 3
          ? row.ch_name
          : "LVB"
    },
    {
      name: "Action",
      wrap: true,
      cell: (row) => (
        <>
          <a
            onClick={() => {
              setDvisible({ mval: true, id: row.magazine_id });
            }}
          >
            <Tooltip title="Delete">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </a>
          <a
            onClick={() => {
              setStep(2);
              setgetdata(row);
              setSelectData(row.m_type);
              setFileUrl();
            }}
          >
            <Tooltip title="Edit">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      )
    }
  ];

  const magazinedelete = async (magazine_id) => {
    const resp = await magazineDelete(magazine_id);
    let notify = notification({
      type: resp.status === 0 ? "error" : "success",
      message: resp.message
    });
    notify();

    if (resp.status === 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
  };

  const getlandmarklist = async () => {
    setmagazinelist(await fetchMagazineList(loginUser.city_id, loginUser.u_type));
  };
  useEffect(async () => {
    setmagazinelist(await fetchMagazineList(loginUser.city_id, loginUser.u_type));
  }, [response]);

  const filterData = () => {
    const filterByType = (event) => {
      return filtereddata.eventType === -1 || event.m_type === filtereddata.eventType;
    };

    const nEvent = getmagazinelist.filter((event) => filterByType(event));

    setnewMagazinelist(nEvent);
  };

  useEffect(() => {
    filterData();
  }, [filtereddata, getmagazinelist]);

  return (
    <>
      <Deletemodal visible={dvisible} setVisible={setDvisible} deletefunc={magazinedelete} />
      <Page title="Magazine | Local Vocal Business Group">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Magazine
            </Typography>
          </Stack>
        </Container>

        {/* Start Magazine Form */}
        {step !== 0 ? (
          <>
            <Button
              onClick={() => {
                setStep(0);
                setFile(null);
                setgetdata({
                  m_type: loginUser?.u_type === 0 ? 0 : 2
                });
              }}
              style={{ marginBottom: "15px" }}
              variant="contained"
              disabled={isFormSubmit}
            >
              Back
            </Button>
            <Card
              style={{
                padding: "25px",
                paddingBottom: "25px",
                marginBottom: "25px"
              }}
            >
              <FormLabel id="demo-radio-buttons-group-label">Display</FormLabel>
              <Grid item xs={12}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <RadioGroup
                    style={{ marginTop: "0px" }}
                    row
                    defaultValue={0}
                    value={getdata.m_type}
                    onChange={(e) => {
                      onChangeFunction(e);
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="m_type"
                  >
                    {loginUser?.u_type == 0 && (
                      <>
                        <FormControlLabel value="0" control={<Radio />} label="LVB" />
                        <FormControlLabel value="1" control={<Radio />} label="State" />
                      </>
                    )}
                    <FormControlLabel value="2" control={<Radio />} label="City" />
                    <FormControlLabel value="3" control={<Radio />} label="Chapter" />
                  </RadioGroup>
                </Stack>
              </Grid>
              {getdata.m_type != 0 ? (
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <label style={{ marginBottom: "10px" }}>
                      Select
                      {getdata.m_type == 1
                        ? " State"
                        : getdata.m_type == 2
                        ? " City"
                        : getdata.m_type == 3
                        ? " Chapter"
                        : " LVB"}
                    </label>
                    <Select
                      style={{ marginTop: "0px" }}
                      required={true}
                      value={
                        option != null &&
                        option?.map((c) => {
                          if (c.value == getdata?.m_type_id) {
                            return { value: c.value, label: c.label };
                          }
                        })
                      }
                      onChange={(e) => {
                        setgetdata({ ...getdata, m_type_id: e.value });
                      }}
                      menuPortalTarget={document.body}
                      options={option}
                    />
                  </Stack>
                </Grid>
              ) : (
                ""
              )}

              {/* PDF Icon & Name Preview */}
              {setfile && step !== 2 && (
                <Stack direction="row" alignItems="center" spacing={1} mt={2}>
                  <PictureAsPdf color="error" fontSize="large" /> {/* PDF Icon */}
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    {setfile?.file?.name}
                  </a>
                </Stack>
              )}

              {/* edit time preview  */}
              {step === 2 &&
                (getdata.document && !fileUrl ? (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PictureAsPdf color="error" fontSize="large" />
                    <a
                      href={`${Routes.API_HOSTNAME}images/magazine/${getdata.document}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getdata.document
                        ? getdata.document.split("_").slice(1).join("_")
                        : getdata.document}
                    </a>
                  </Stack>
                ) : fileUrl ? (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PictureAsPdf color="error" fontSize="large" />
                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                      {setfile?.file?.name}
                    </a>
                  </Stack>
                ) : null)}

              <Stack direction="row" alignItems={"center"} spacing={2}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ height: 40 }}
                  style={{ marginTop: "10px" }}
                >
                  Click To Upload File
                  <input
                    id="file"
                    name="file"
                    type="file"
                    accept="application/pdf"
                    hidden
                    // onChange={(event) => {
                    //   const selectedFile = event.target.files[0];

                    //   // ✅ Check if file is selected and its type is PDF
                    //   if (selectedFile && selectedFile.type === "application/pdf") {
                    //     setFile({ file: selectedFile });
                    //     setFileUrl(URL.createObjectURL(selectedFile));
                    //   } else {
                    //     let notify = notification({
                    //       type: "error",
                    //       message: "Only PDF files are allowed"
                    //     });
                    //     notify();
                    //     setFile(null);
                    //     setFileUrl("");
                    //   }
                    // }}
                    onChange={(event) => {
                      const selectedFile = event.target.files[0];

                      if (!selectedFile) return;

                      // ✅ File type validation (Only PDF allowed)
                      if (selectedFile.type !== "application/pdf") {
                        let notify = notification({
                          type: "error",
                          message: "Only PDF files are allowed"
                        });
                        notify();
                        setFile(null);
                        setFileUrl("");
                        return;
                      }

                      // ✅ File size validation (Max: 2MB)
                      const maxSize = 20 * 1024 * 1024;
                      if (selectedFile.size > maxSize) {
                        let notify = notification({
                          type: "error",
                          message: "File size must be less than 20MB"
                        });
                        notify();
                        setFile(null);
                        setFileUrl("");
                        return;
                      }

                      // ✅ If validation passes, set the file
                      setFile({ file: selectedFile });
                      setFileUrl(URL.createObjectURL(selectedFile));
                    }}
                    className="form-control"
                  />
                </Button>
              </Stack>

              <LoadingButton
                sx={{  marginTop: "15px" }}
                type="button"
                variant="contained"
                className="loadingBtn"
                loading={isFormSubmit}
                onClick={handleSubmit}
              >
                submit
              </LoadingButton>
            </Card>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                setStep(1);
              }}
              style={{ marginBottom: "15px" }}
              variant="contained"
            >
              Add New Magazine
            </Button>

            <Card className="p-20">
              <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={3}>
                  <Stack sx={{ my: "5px" }}>
                    <label>Select Type</label>
                    <Select
                      menuPortalTarget={document.body}
                      value={EventType.map((c) => {
                        if (c.value == filtereddata.eventType) {
                          return { value: c.value, label: c.label };
                        }
                      })}
                      onChange={(e) => {
                        setFilteredData({
                          ...filtereddata,
                          eventType: e.value
                        });
                      }}
                      options={EventType}
                    />
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    textAlign: "center"
                  }}
                >
                  <Button
                    onClick={async () => {
                      setFilteredData({
                        eventType: -1
                      });
                    }}
                    variant="contained"
                  >
                    clear
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <Card className="p-20" sx={{ mt: 2 }}>
              {getmagazinelist && (
                <>
                  <DataTable
                    filter
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={newMagazinelist != null && newMagazinelist}
                  />
                </>
              )}
            </Card>
          </>
        )}
        {/* End Magazine Form */}
      </Page>
    </>
  );
}
