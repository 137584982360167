import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import Page from "../components/Page";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Check, DeleteSharp } from "@mui/icons-material";
import {
  fetchallcitylist,
  fetchchapterbyuser,
  fetchThankYouNoteWaitingsForApprovalList,
} from "src/utils/common";
import { CSVLink } from "react-csv";
import {
  approvedThankyouNoteRequest,
  deleteThankyouNoteRequest,
} from "src/api/commonapi";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";

const ThankyouNoteApproval = () => {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [businessData, setBusinessData] = useState([]);
  const [newBusinessData, setNewBusinessData] = useState([]);
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [filterBusiness, setFilterBusiness] = useState({
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,

    chpt_id: 0,

    search: "",
    s_date: null,
    e_date: null,
  });
  const filterData = () => {
    const isWithinDateRange = (date) =>
      filterBusiness.e_date &&
      filterBusiness.s_date &&
      moment(date, "DD-MM-YYYY").isBetween(
        filterBusiness.s_date,
        filterBusiness.e_date,
        "days",
        "[]"
      );

    const matchesSearchTerm = (business) => {
      const searchTerm = filterBusiness.search.toLowerCase();
      return (
        business.Given.toLowerCase().includes(searchTerm) ||
        business.Receiver.toLowerCase().includes(searchTerm) ||
        business.b_amount.toString().toLowerCase().includes(searchTerm) ||
        business.b_thanksnote.toLowerCase().includes(searchTerm)
      );
    };

    const matchesChapterId = (business) => {
      if (filterBusiness.chpt_id != 0 || filterBusiness.city_id != 0) {
        if (filterBusiness.chpt_id != 0) {
          return (
            business.c1_chpt_id === filterBusiness.chpt_id ||
            business.c2_chpt_id === filterBusiness.chpt_id
          );
          //
        } else if (filterBusiness.city != 0) {
          const cityChapters = chaptlist
            .filter((chapter) => chapter.city_id === filterBusiness.city_id)
            .map((chapter) => chapter.chpt_id);

          return (
            cityChapters.includes(business.c1_chpt_id) || cityChapters.includes(business.c2_chpt_id)
          );
        }
      } else return true;
    };

    const nbusinessData =
      businessData?.filter(
        (business) =>
          (isWithinDateRange(business.b_date) ||
            !filterBusiness.e_date ||
            !filterBusiness.s_date) &&
          matchesSearchTerm(business) &&
          matchesChapterId(business)
      ) || [];

    setNewBusinessData(nbusinessData);
  };

  useEffect(async () => {
    if (filterBusiness.search != undefined) {
      await filterData();
    }
  }, [filterBusiness, businessData]);

  useEffect(() => {
    getBusinessList();
  }, []);
  const getBusinessList = async () => {
    setBusinessData(
      await fetchThankYouNoteWaitingsForApprovalList(loginUser?.city_id)
    );
  };

  const editThankYouNote = async (id) => {
    const resp = await approvedThankyouNoteRequest(id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getBusinessList();
    }
  };
  const deleteThankYouNote = async (id) => {
    const resp = await deleteThankyouNoteRequest(id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      setDvisible({ mval: false, id: 0 });
      getBusinessList();
    }
  };
  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row, key) => row?.b_w_id,
      width: "50px",
      sortable: true,
    },

    {
      name: "Receiver",
      wrap: true,
      selector: (row) => row?.Receiver,
      sortable: true,
    },
    {
      name: "Giver",
      wrap: true,
      selector: (row) => row?.Given,
      sortable: true,
    },
    {
      name: "Amount",
      wrap: true,
      selector: (row) => row?.b_amount,
      width: "150px",

      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row?.b_date,
      width: "120px",
      sortable: true,
    },
    {
      name: "Comment ",
      wrap: true,
      selector: (row) => row?.b_thanksnote,
      wrap: true,
      sortable: true,
    },

    {
      name: "Action",
      wrap: true,
      sortable: true,
      width: "120px",
      selector: (row) => (
        <>
          <Tooltip title="Approve Thankyou Note">
            <IconButton
              onClick={() => {
                editThankYouNote(row.b_w_id);
                // getBusinnesAmount(row.b_amount, row.b_id);
              }}
            >
              <Check color="success" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove Thankyou Note">
            <IconButton
              onClick={() => {
                // deleteThankYouNote;
                setDvisible({ mval: true, id: row.b_w_id });
              }}
            >
              <DeleteSharp color="error" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  const expo_columns = [
    { label: "No", key: "b_w_id" },
    { label: "Receiver", key: "Receiver" },
    { label: "Giver", key: "Given" },
    { label: "Date", key: "b_date" },
    { label: "Amount", key: "b_amount" },
    { label: "Thankyou Note", key: "b_thanksnote" },
  ];

  const [citylist, setcitylist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
  };
  useEffect(async () => {
    getallcitylist();
    setchaplist(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  }, []);

  const [chaptlist, setchaplist] = useState([]);

  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deleteThankYouNote}
      />
      <Page title="Business Approval | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Thankyou Note Approval List
            </Typography>
          </Stack>

          <Card className="p-20">
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", alignItems: "flex-end" }}
            >
              <Grid item xs={2}>
                <Stack>
                  <label>Select City</label>
                  <Select
                    menuPortalTarget={document.body}
                    isDisabled={
                      loginUser?.u_type == 1 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id != 1 &&
                        loginUser?.management_p == 1)
                        ? true
                        : false
                    }
                    options={[
                      {
                        value: 0,
                        label: "All City",
                      },
                      ...citylist.map((city) => {
                        return {
                          label: city?.city_name,
                          value: city?.city_id,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setFilterBusiness({
                        ...filterBusiness,
                        city_id: e.value,
                        chpt_id: 0,
                      });
                    }}
                    value={
                      filterBusiness.city_id == 0
                        ? {
                            value: 0,
                            label: "All City",
                          }
                        : citylist.map((city) => {
                            return (
                              filterBusiness.city_id == city.city_id && {
                                value: city.city_id,
                                label: city.city_name,
                              }
                            );
                          })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack spacing={3}>
                  <label>Select Chapter</label>
                  <Select
                    name="chpt_id"
                    menuPortalTarget={document.body}
                    onChange={(e) => {
                      setFilterBusiness({
                        ...filterBusiness,
                        chpt_id: e.value,
                      });
                    }}
                    options={[
                      {
                        value: 0,
                        label: "All Chapter",
                      },
                      ...(chaptlist &&
                        chaptlist
                          ?.map((chpt) => {
                            if (filterBusiness?.city_id != 0) {
                              if (filterBusiness?.city_id == chpt?.city_id) {
                                return {
                                  value: chpt.chpt_id,
                                  label: chpt.chpt_name,
                                };
                              }
                            } else {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          })
                          .filter((f) => f != undefined)),
                    ]}
                    value={
                      filterBusiness.chpt_id == 0
                        ? { value: 0, label: "All Chapter" }
                        : chaptlist &&
                          chaptlist.map((c) => {
                            if (c.chpt_id == filterBusiness.chpt_id) {
                              return {
                                value: c.chpt_id,
                                label: c.chpt_name,
                              };
                            }
                          })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={10}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Search"
                    name="name"
                    autoCorrect="off"
                    value={filterBusiness.search || ""}
                    onChange={(e) => {
                      setFilterBusiness({
                        ...filterBusiness,
                        search: e.target.value,
                      });
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={5}>
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack spacing={10} sx={{ marginRight: "5px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        type="date"
                        name="s_date"
                        label="Start Date"
                        value={
                          filterBusiness?.s_date != undefined
                            ? moment(filterBusiness.s_date, "DD-MM-YYYY")
                            : null
                        }
                        inputFormat="dd/MM/yyyy"
                        onChange={(newDate) => {
                          setFilterBusiness({
                            ...filterBusiness,
                            s_date: newDate,
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>

                  <Stack spacing={10} sx={{ marginRight: "5px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        type="date"
                        name="e_date"
                        label="End Date"
                        value={
                          filterBusiness?.e_date != undefined
                            ? moment(filterBusiness.e_date, "DD-MM-YYYY")
                            : null
                        }
                        inputFormat="dd/MM/yyyy"
                        onChange={(newDate) => {
                          setFilterBusiness({
                            ...filterBusiness,
                            e_date: newDate,
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>

                  <Stack spacing={10} sx={{ marginRight: "5px" }}>
                    <Button
                      onClick={async () => {
                        setFilterBusiness({
                          city_id:
                            loginUser?.u_type == 1 ||
                            (loginUser?.u_type == 2 &&
                              loginUser?.p_user_id != 1 &&
                              loginUser?.management_p == 1)
                              ? loginUser?.city_id
                              : 0,
                          chpt_id: 0,
                          search: "",
                          s_date: null,
                          e_date: null,
                        });
                      }}
                      variant="contained"
                    >
                      clear
                    </Button>
                  </Stack>
                </div>
              </Grid>
            </Grid>
          </Card>

          <Card className=" p-20" style={{ marginTop: "15px" }}>
            {newBusinessData && newBusinessData?.length != 0 && (
              <Button className="btn btn-export">
                <CSVLink
                  data={newBusinessData}
                  headers={expo_columns}
                  filename={"ThankYou Note Approval List.csv"}
                  className="btn btn-primary test"
                  target="_blank"
                >
                  Export CSV
                </CSVLink>
              </Button>
            )}

            <DataTable
              style={{ overflow: "hidden" }}
              filter
              pagination
              highlightOnHover
              columns={columns}
              data={
                newBusinessData && newBusinessData?.length != 0
                  ? newBusinessData
                  : []
              }
            />
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default ThankyouNoteApproval;
