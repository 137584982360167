/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Deletemodal from "./DeleteModal";
import Page from "src/components/Page";
import {
  Button,
  Card,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Add, ArrowLeft, DeleteSharp, EditSharp, UploadFile } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { notification } from "src/utils/messages";
import { Routes } from "src/constant/api_url";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import { fetchTrainingWebinarList, PollSelect } from "src/utils/common";
import styled from "styled-components";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import Select from "react-select";
import {
  deleteTrainingWebinar,
  insertTrainingWebinar,
  updateTrainingWebinar
} from "src/api/commonapi";

export default function TrainingAndWebinar() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [page, setPage] = useState(0);
  const handlePageChange = (newPageValue) => {
    setPage(newPageValue);
  };

  const [option, setOption] = useState(null);
  const [setfile, setFile] = useState({});
  const [fileimage, setfileimage] = useState();
  const [newTrainingWebinarList, setNewTrainingWebinarList] = useState([]);
  const [trainingWebinarList, setTrainingWebinarList] = useState([]);
  const [response, setresponse] = useState(0);
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const getOptionsValueList = async (type = 0) => {
    let optionData = [];
    if (type != 0) {
      const resp = await PollSelect(type, loginUser?.u_type, loginUser?.city_id);
      resp?.map((e) => optionData.push({ value: e.value, label: e.name }));
      setOption([{ value: 0, label: "Select" }, ...optionData]);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1
  });

  const [formData, setFormData] = useState({
    t_w_img: "",
    t_w_title: "",
    t_w_desc: "",
    t_w_link: "",
    t_w_sdate: "",
    t_w_edate: "",
    t_w_send_to: "0",
    t_w_for_type: "0", // 0 - LVB , 1- State, 2- City, 3- Chapter
    t_w_for_type_id: ""
  });

  const resetFormData = () => {
    setFormData({
      t_w_img: "",
      t_w_title: "",
      t_w_desc: "",
      t_w_link: "",
      t_w_sdate: "",
      t_w_edate: "",
      t_w_send_to: "0",
      t_w_for_type: "0",
      t_w_for_type_id: ""
    });
  };

  const TrainingWebinarFor = [
    { label: "All", value: -1 },
    { label: "LVB ", value: 0 },
    { label: "State", value: 1 },
    { label: "City", value: 2 },
    { label: "Chapter", value: 3 }
  ];

  const TrainingWebinarStatus = [
    { label: "All", value: 0 },
    { label: "Open ", value: 1 },
    { label: "Close", value: 2 }
  ];

  const [filtereddata, setFilteredData] = useState({
    trainingWebinarFor: -1,
    trainingWebinarStatus: 0,
    search: "",
    startDate: "",
    endDate: ""
  });

  const handleFormSubmit = async (newData = undefined) => {
    try {
      let fData = new FormData();

      if (formData != undefined && formData.t_w_id != undefined) {
        fData.append("t_w_title", formData.t_w_title);
        fData.append("t_w_desc", formData.t_w_desc);
        fData.append("t_w_img", setfile.file);
        fData.append("t_w_link", formData.t_w_link);
        fData.append("t_w_sdate", formData.t_w_sdate);
        fData.append("t_w_edate", formData.t_w_edate);
        fData.append("t_w_send_to", formData.t_w_send_to);
        fData.append("t_w_for_type", formData.t_w_for_type);
        fData.append("t_w_for_type_id", formData.t_w_for_type_id);
        fData.append("t_w_id", formData.t_w_id);

        const resp = await updateTrainingWebinar(fData);
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message
        });
        if (resp.status == 1) {
          notify();
          resetFormData();
          setPage(0);
          getTrainingWebinarList();
        } else {
          notify();
        }
      } else {
        // Insert the form data
        if (
          !setfile ||
          Object.keys(setfile).length === 0 ||
          !setfile.file ||
          formData.t_w_title == "" ||
          formData.t_w_desc == "" ||
          formData.t_w_link == "" ||
          formData.t_w_sdate == "" ||
          formData.t_w_edate == "" ||
          formData.t_w_send_to == "" ||
          formData.t_w_for_type == "" ||
          (formData.t_w_for_type != 0 && formData.t_w_for_type_id == "")
        ) {
          let notify = notification({
            type: "error",
            message: "Please enter all required fields to submit"
          });
          notify();
        } else {
          // API Call
          fData.append("t_w_title", formData.t_w_title);
          fData.append("t_w_desc", formData.t_w_desc);
          fData.append("t_w_img", setfile.file);
          fData.append("t_w_link", formData.t_w_link);
          fData.append("t_w_sdate", formData.t_w_sdate);
          fData.append("t_w_edate", formData.t_w_edate);
          fData.append("t_w_send_to", formData.t_w_send_to);
          fData.append("t_w_for_type", formData.t_w_for_type);
          fData.append("t_w_for_type_id", formData.t_w_for_type_id);

          const resp = await insertTrainingWebinar(fData);
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          if (resp.status == 1) {
            notify();
            resetFormData();
            setPage(0);
            getTrainingWebinarList();
          } else {
            notify();
          }
        }
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const trainingwebinardelete = async (t_w_id) => {
    const resp = await deleteTrainingWebinar(t_w_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message
    });
    notify();

    if (resp.status == 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
  };

  const getTrainingWebinarList = async () => {
    setTrainingWebinarList(await fetchTrainingWebinarList(loginUser.city_id, loginUser.u_type));
  };

  useEffect(async () => {
    setTrainingWebinarList(await fetchTrainingWebinarList(loginUser.city_id, loginUser.u_type));
  }, [response]);

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row.t_w_id,
      sortable: true,
      width: "50px"
    },
    {
      name: "Image",
      wrap: true,
      width: "150px",
      cell: (row) => {
        return (
          <img
            src={`${Routes.API_HOSTNAME}images/training_webinar/${row.t_w_img}`}
            alt="img"
            width="100"
          />
        );
      }
    },
    {
      name: "Title",
      wrap: true,
      width: "auto",
      selector: (row) => (row.t_w_title != null && row.t_w_title != "" ? row.t_w_title : "-"),
      sortable: true
    },
    {
      name: "Description",
      wrap: true,
      width: "auto",
      selector: (row) => (row.t_w_desc != null && row.t_w_desc != "" ? row.t_w_desc : "-"),
      sortable: true
    },
    {
      name: "URL",
      wrap: true,
      width: "120px",
      selector: (row) => ((row.t_w_link != null) & (row.t_w_link != "") ? row.t_w_link : "-"),
      sortable: true
    },
    {
      name: "Training/Webinar Send To",
      width: "130px",
      wrap: true,
      selector: (row) => (row.t_w_send_to == 1 ? "LT" : row.t_w_send_to == 2 ? "Members" : "All")
    },
    {
      name: "Training/Webinar For",
      width: "130px",
      wrap: true,
      selector: (row) =>
        row.t_w_for_type == 1
          ? "State"
          : row.t_w_for_type == 2
          ? "City"
          : row.t_w_for_type == 3
          ? "Chapter"
          : "LVB"
    },
    {
      name: "Location",
      wrap: true,
      width: "100px",

      selector: (row) =>
        loginUser.u_type != 0
          ? row.city_name
          : row.t_w_for_type == 1
          ? row.s_name
          : row.t_w_for_type == 2
          ? row.city_name
          : row.t_w_for_type == 3
          ? row.ch_name
          : "LVB"
    },
    {
      name: "Start Date",
      wrap: true,
      width: "120px",
      selector: (row) => row.t_w_sdate,
      sortable: true
    },
    {
      name: "End Date",
      selector: (row) => row.t_w_edate,
      wrap: true,
      width: "120px",
      sortable: true
    },
    {
      name: "Action",
      wrap: true,
      width: "150px",
      cell: (row) => (
        <>
          <button
            onClick={() => {
              setPage(2);
              setFormData(row);
              getOptionsValueList(row.t_w_for_type);
              setfileimage();
            }}
            style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}
          >
            <Tooltip title="Edit">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </button>
          <button
            onClick={() => {
              setDvisible({ mval: true, id: row.t_w_id });
            }}
            style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}
          >
            <Tooltip title="Delete">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </button>
        </>
      )
    }
  ];

  const filterData = () => {
    const filterByType = (trainingwebinar) => {
      return (
        filtereddata.trainingWebinarFor == -1 ||
        trainingwebinar.t_w_for_type === filtereddata.trainingWebinarFor
      );
    };

    const filterByOpenClose = (trainingwebinar) => {
      if (filtereddata.trainingWebinarStatus === 1) {
        // Open trainingWebinar: future trainingWebinar only
        return moment(trainingwebinar.t_w_edate, "DD-MM-YYYY hh:mm A").isSameOrAfter(moment());
      }
      if (filtereddata.trainingWebinarStatus === 2) {
        // Closed trainingWebinar: past trainingWebinar only
        return moment(trainingwebinar.t_w_edate, "DD-MM-YYYY hh:mm A").isBefore(moment());
      }
      return true;
    };

    const filterBySearchType = (trainingwebinar) => {
      let searchFilterText = filtereddata.search.toLowerCase();
      return (
        filtereddata.search == "" ||
        trainingwebinar?.t_w_title?.toLowerCase().includes(searchFilterText) ||
        trainingwebinar?.t_w_desc?.toLowerCase().includes(searchFilterText) ||
        trainingwebinar?.Training_Webinar_For?.toLowerCase().includes(searchFilterText) ||
        trainingwebinar?.t_w_link?.toLowerCase().includes(searchFilterText)
      );
    };

    const nTrainingWebinar = trainingWebinarList.filter(
      (trainingwebinar) =>
        filterByType(trainingwebinar) &&
        filterByOpenClose(trainingwebinar) &&
        filterBySearchType(trainingwebinar)
    );

    setNewTrainingWebinarList(nTrainingWebinar);
  };

  useEffect(() => {
    filterData();
  }, [filtereddata, trainingWebinarList]);

  return (
    <>
      <Deletemodal visible={dvisible} setVisible={setDvisible} deletefunc={trainingwebinardelete} />
      <Page title="Training & Webinar | Local Vocal Business Group">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Training & Webinar
            </Typography>
            {page === 0 ? (
              <Button variant="contained" color="primary" onClick={() => handlePageChange(1)}>
                <Add sx={{ fontSize: "20px", marginRight: "5px" }} />
                Add Training or Webinar
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  resetFormData();
                  setPage(0);
                  setfileimage();
                }}
              >
                <ArrowLeft sx={{ fontSize: "20px", marginRight: "5px" }} />
                Back
              </Button>
            )}
          </Stack>

          {page !== 0 ? (
            <>
              <Card style={{ padding: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel>Training/Webinar Send to</FormLabel>
                    <Stack spacing={3}>
                      <RadioGroup
                        style={{ marginTop: "0px" }}
                        row
                        defaultValue={0}
                        value={formData?.t_w_send_to}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            t_w_send_to: e.target.value
                          });
                        }}
                        name="t_w_send_to"
                      >
                        <FormControlLabel value="0" control={<Radio />} label="All" />
                        <FormControlLabel value="1" control={<Radio />} label="LT" />
                        <FormControlLabel value="2" control={<Radio />} label="Members" />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel id="demo-radio-buttons-group-label">Training/Webinar For</FormLabel>
                    <Stack spacing={3}>
                      <RadioGroup
                        style={{ marginTop: "0px" }}
                        row
                        defaultValue={0}
                        value={formData?.t_w_for_type}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            t_w_for_type: e.target.value,
                            t_w_for_type_id: ""
                          });
                          getOptionsValueList(e.target.value);
                        }}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="t_w_for_type"
                      >
                        {loginUser?.u_type === 0 && (
                          <>
                            <FormControlLabel value="0" control={<Radio />} label="LVB" />
                            <FormControlLabel value="1" control={<Radio />} label="State" />
                          </>
                        )}
                        <FormControlLabel value="2" control={<Radio />} label="City" />
                        <FormControlLabel value="3" control={<Radio />} label="Chapter" />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                  {formData.t_w_for_type != 0 ? (
                    <Grid item xs={12}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Select
                          {formData?.t_w_for_type == 1
                            ? " State"
                            : formData?.t_w_for_type == 2
                            ? " City"
                            : formData?.t_w_for_type == 3
                            ? " Chapter"
                            : " LVB"}
                        </label>
                        <Select
                          required={true}
                          error={true}
                          style={{ marginTop: "0px" }}
                          value={
                            option != null &&
                            option.map((c) => {
                              if (c.value === formData?.t_w_for_type_id) {
                                return { value: c.value, label: c.label };
                              }
                            })
                          }
                          onChange={(e) => {
                            setFormData({ ...formData, t_w_for_type_id: e.value });
                          }}
                          menuPortalTarget={document.body}
                          options={option}
                        />
                      </Stack>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12}>
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                      tabIndex={-1}
                      sx={{ textTransform: "none", p: "10px" }}
                      startIcon={<UploadFile />}
                    >
                      Click to Upload file
                      <VisuallyHiddenInput
                        type="file"
                        required
                        multiple={false}
                        accept="image/*,.jpg,.jpeg,.png,.gif"
                        onChange={(event) => {
                          const uploadedFile = event.currentTarget.files[0];

                          // Check if file exists and validate its size
                          if (uploadedFile && uploadedFile.size > 2097152) {
                            let notify = notification({
                              type: "error",
                              message: "File size exceeds 2MB. Please upload a smaller file."
                            });
                            notify();
                            return;
                          }

                          // If valid, update the state
                          setFile({ file: uploadedFile });
                          setfileimage(URL.createObjectURL(uploadedFile));
                        }}
                      />
                    </Button>

                    {/* Display uploaded Image */}
                    {page === 1 &&
                      (fileimage !== undefined ? (
                        <div style={{ marginTop: "10px" }}>
                          <h4>Image Preview</h4>
                          <div className="position-relative">
                            <img
                              src={fileimage}
                              alt="Uploaded file preview"
                              style={{
                                width: "450px",
                                objectFit: "contain",
                                height: "250px",
                                boxShadow: "0 0 5px #ccc",
                                borderRadius: "5px"
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      ))}
                    {page === 2 &&
                      (fileimage !== undefined ? (
                        <div style={{ marginTop: "10px" }}>
                          <h4>Image Preview</h4>
                          <div className="position-relative">
                            <img
                              src={fileimage}
                              alt="Uploaded file preview"
                              style={{
                                width: "450px",
                                objectFit: "contain",
                                height: "250px",
                                boxShadow: "0 0 5px #ccc",
                                borderRadius: "5px"
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginTop: "10px" }}>
                          <h4>Image Preview</h4>
                          <div className="position-relative">
                            <img
                              src={`${Routes.API_HOSTNAME}images/training_webinar/${formData?.t_w_img}`}
                              alt="Uploaded file preview"
                              style={{
                                width: "450px",
                                objectFit: "contain",
                                height: "250px",
                                boxShadow: "0 0 5px #ccc",
                                borderRadius: "5px"
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={3}>
                      <TextField
                        required
                        type="text"
                        label="Title"
                        fullWidth
                        value={formData?.t_w_title}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            t_w_title: e.target.value
                          });
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      required
                      multiline
                      fullWidth
                      rows={4}
                      value={formData?.t_w_desc}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          t_w_desc: e.target.value
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={3}>
                      <TextField
                        required
                        type="text"
                        label="URL"
                        fullWidth
                        value={formData?.t_w_link}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            t_w_link: e.target.value
                          });
                        }}
                      />
                    </Stack>
                  </Grid>

                  {/* grid 6 add start date and end date */}
                  <Grid item xs={6}>
                    <Stack spacing={3}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        style={{ marginTop: "20px" }}
                      >
                        <DateTimePicker
                          type="date"
                          name="t_w_sdate"
                          label="Start Date "
                          inputFormat="dd/MM/yyyy hh:mm a"
                          value={
                            formData.t_w_sdate !== ""
                              ? moment(formData.t_w_sdate, "DD-MM-YYYY hh:mm A").toDate()
                              : null
                          }
                          onChange={(newDate) => {
                            setFormData({
                              ...formData,
                              t_w_sdate: moment(newDate).format("DD-MM-YYYY hh:mm A")
                            });
                          }}
                          renderInput={(params) => <TextField {...params} required />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={3}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        style={{ marginTop: "20px" }}
                      >
                        <DateTimePicker
                          type="date"
                          name="t_w_edate"
                          label="End Date "
                          inputFormat="dd/MM/yyyy hh:mm a"
                          value={
                            formData.t_w_edate != ""
                              ? moment(formData.t_w_edate, "DD-MM-YYYY hh:mm A").toDate()
                              : null
                          }
                          onChange={(newDate) => {
                            setFormData({
                              ...formData,
                              t_w_edate: moment(newDate).format("DD-MM-YYYY hh:mm A")
                            });
                          }}
                          renderInput={(params) => <TextField {...params} required />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>

                  {/* submit and rest form state */}
                  <Grid item xs={12} spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ marginRight: "5px" }}
                      onClick={() => handleFormSubmit()} // handleFormSubmit function
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        resetFormData();
                        setPage(0);
                        setfileimage();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </>
          ) : (
            <>
              <Card className="p-20 mb-15">
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={3}>
                    <Stack sx={{ my: "5px" }}>
                      <label>Training/Webinar For</label>
                      <Select
                        menuPortalTarget={document.body}
                        value={TrainingWebinarFor.map((c) => {
                          if (c.value == filtereddata.trainingWebinarFor) {
                            return { value: c.value, label: c.label };
                          }
                        })}
                        onChange={(e) => {
                          setFilteredData({
                            ...filtereddata,
                            trainingWebinarFor: e.value
                          });
                        }}
                        options={TrainingWebinarFor}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={3}>
                    <Stack>
                      <label>Training/Webinar Status</label>
                      <Select
                        menuPortalTarget={document.body}
                        value={TrainingWebinarStatus.map((c) => {
                          if (c.value == filtereddata.trainingWebinarStatus) {
                            return { value: c.value, label: c.label };
                          }
                        })}
                        onChange={(e) => {
                          setFilteredData({
                            ...filtereddata,
                            trainingWebinarStatus: e.value
                          });
                        }}
                        options={TrainingWebinarStatus}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={4}>
                    <Stack spacing={10}>
                      <TextField
                        style={{ marginTop: "0px" }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Search"
                        name="name"
                        autoCorrect="off"
                        value={filtereddata.search}
                        onChange={(e) => {
                          setFilteredData({
                            ...filtereddata,
                            search: e.target.value
                          });
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end"
                    }}
                  >
                    <Button
                      onClick={async () => {
                        setFilteredData({
                          trainingWebinarFor: -1,
                          trainingWebinarStatus: 0,
                          search: "",
                          startDate: "",
                          endDate: ""
                        });
                      }}
                      variant="contained"
                    >
                      clear
                    </Button>
                  </Grid>
                </Grid>
              </Card>
              <Card className="p-2" style={{ padding: "15px" }}>
                {trainingWebinarList && (
                  <>
                    <DataTable
                      filter
                      pagination
                      highlightOnHover
                      columns={columns}
                      data={newTrainingWebinarList != null && newTrainingWebinarList}
                    />
                  </>
                )}
              </Card>
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
