import React, { useState, useEffect } from "react";

// material
import { Badge, Box, Card, Grid, Modal, Typography } from "@mui/material";
import Select from "react-select";
import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Page from "../components/Page";

import { notification } from "src/utils/messages";
import { CSVLink } from "react-csv";
import {
  Add,
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
  FileUpload,
  HdrPlus,
  Image,
  ImageSearchOutlined,
  ImageSearchRounded,
  Photo,
  PlusOne,
  RemoveRedEye,
} from "@mui/icons-material";
import "../Assets/css/style.css";
import { styled } from "@mui/material/styles";
import DataTable from "react-data-table-component";
import { Routes } from "../constant/api_url";
import {
  getPinAssignReport,
  insertpinBadge,
  updatePinFunction,
} from "src/api/commonapi";
import { fetchPinList } from "src/utils/common";
import Label from "src/components/Label";
import { assign } from "lodash";

export default function LvbPinBadge() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2,
  };

  const pinAssignModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2,
  };
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const memberActiveType = [
    { value: -1, label: "All" },
    { value: 1, label: "Active" },
    { value: 0, label: "De-Active" },
  ];
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const [addEditModal, setAddEditModal] = useState(false);
  const [pinData, setPinData] = useState({
    name: "",
    file: "",
    pin_point: "",
  });
  const [tableData, setTableData] = useState([]);
  const toggleAddEditModal = () => setAddEditModal(!addEditModal);

  const [pinDataModal, setPinDataModal] = useState(false);
  const togglePinDataModal = () => setPinDataModal(!pinDataModal);
  const resetFormData = () => {
    setPinData({
      name: "",
      file: "",
      status: "1",
      description: "",
    });
  };
  const handlePinSubmit = async (newData = undefined) => {
    if (newData != undefined ? newData : pinData.p_id != undefined) {
      // update Existing Pin
      if (
        (newData != undefined ? newData : pinData).name != "" &&
        (newData != undefined ? newData : pinData).file != ""
      ) {
        let fnData = new FormData();
        fnData.append(
          "p_id",
          newData != undefined ? newData.p_id : pinData.p_id
        );
        fnData.append(
          "name",
          newData != undefined ? newData.name : pinData.name
        );
        fnData.append(
          "pin_point",
          newData != undefined ? newData.pin_point : pinData.pin_point
        );
        fnData.append(
          "pig_image",
          newData != undefined ? newData.file : pinData.file
        );
        fnData.append(
          "status",
          newData != undefined ? newData.status : pinData.status
        );
        fnData.append(
          "description", 
          newData != undefined ? newData.description : pinData.description
        );

        let resp = await updatePinFunction(fnData);
        if (resp.status != 0) {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message,
          });
          notify();
          resetFormData();
          newData == undefined && toggleAddEditModal();
          getPinBadge();
        } else {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message,
          });
          notify();
        }
      } else {
        let notify = notification({
          type: "error",
          message: "Please Fill All Fields data",
        });
        notify();
      }
    } else {
      // Insert New Pin
      if (pinData.name != "" && pinData.file != "") {
        let fnData = new FormData();
        fnData.append("name", pinData.name);
        fnData.append("pig_image", pinData.file);
        fnData.append("pin_point", pinData.pin_point);
        fnData.append("status", pinData.status);
        fnData.append("description", pinData.description ? pinData.description : "");
        let resp = await insertpinBadge(fnData);
        if (resp.status != 0) {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message,
          });
          notify();
          resetFormData();
          toggleAddEditModal();
          getPinBadge();
        } else {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message,
          });
          notify();
        }
      } else {
        let notify = notification({
          type: "error",
          message: "Please Fill All Fields data",
        });
        notify();
      }
    }
  };

  const getPinBadge = async () => {
    let res = await fetchPinList(0);
    setTableData(res);
  };
  useEffect(() => {
    getPinBadge();
  }, []);

  const [exportPinList, setExportPinList] = useState([]);
  const [exportFileName, setExportFileName] = useState("");
  const [filter, setFilter] = useState({
    chpt_id: 0,
    m_id: 0,
    mActive: -1,
    startDate: "",
    endDate: "",
  });

  const activeAndDeActiveMemberFilter = async (activeType) => {
    setExportPinList([]);
    let resp = await getPinAssignReport({
      ...filter,
      mActive: activeType,
      p_id: currentPin,
    });
    if (resp.status == 1) {
      setExportPinList(resp.data);
    } else {
      setExportPinList([]);
    }
  };

  const [currentPin, setCurrentPin] = useState(0);
  const exportFilteredPin = async (p_id) => {
    setExportPinList([]);
    let resp = await getPinAssignReport({ ...filter, p_id: p_id });
    if (resp.status == 1) {
      setExportPinList(resp.data);
    } else {
      setExportPinList([]);
      // setExportFileName("");
      // let notify = notification({
      //   type: "error",
      //   message: "No Data Found",
      // });
      // notify();
    }
  };

  const expo_columns = [
    { label: "No", key: "p_id" },
    { label: "Name", key: "name" },
    { label: "Pin Point", key: "pin_point" },
    { label: "Pin Description", key: "description" },
    { label: "No. Assign Member", key: "counter" },
  ];
  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row?.p_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      wrap: true,
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => (
        <>
          <img
            title={row.name}
            src={`${Routes.API_HOSTNAME}pin-image/${row?.image}`}
            width="90"
            alt={row.name}
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "Status",

      wrap: true,
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Label
            variant={"ghost"}
            color={row.status == 1 ? "success" : "error"}
          >
            {row.status == 1 ? "Active" : "Disabled"}
          </Label>

          <Button
            className="bnt-btn-primary"
            variant="contained"
            color={row.status == 1 ? "error" : "primary"}
            style={{ padding: "2px 10px", marginTop: "5px" }}
            onClick={async (e) => {
              let newData = {
                name: row.name,
                p_id: row.p_id,
                file: row.image,
                pin_point: row.pin_point,
                description: row.description,
                status: row.status == 1 ? 0 : 1,
              };
              await handlePinSubmit(newData);
            }}
          >
            {row.status == 1 ? "Disabled" : " Active"}
          </Button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Pin Point",
      selector: (row) => row?.pin_point,
      sortable: true,
      width: "100px",
    },
    {
      name: "Pin Description",
      selector: (row) => row?.description,
      wrap: true,
      sortable: true,
    },
    {
      name: "No. Assign Member",
      wrap: true,
      width: "120px",

      selector: (row) => row?.counter,
      sortable: true,
    },
    {
      name: "Action",
      wrap: true,
      sortable: true,
      width: "100px",
      selector: (row) => (
        <>
          <>
            <Tooltip title="Edit Pin">
              <IconButton
                onClick={() => {
                  setPinData({
                    name: row.name,
                    p_id: row.p_id,
                    file: row.image,
                    pin_point: row.pin_point,
                    status: row.status,
                    description: row.description,
                  });
                  toggleAddEditModal();
                }}
              >
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </>
          <>
            <a
              onClick={() => {
                setCurrentPin(row?.p_id);
                setExportFileName(row?.name);
                exportFilteredPin(row?.p_id);
                togglePinDataModal();
              }}
            >
              <Tooltip title="See Assign Member List">
                <IconButton>
                  <RemoveRedEye color="success" />
                </IconButton>
              </Tooltip>
            </a>
            {/* <Button
              className="btn btn-export"
              style={{ marginBottom: "15px", marginTop: "0" }}
              variant="contained"
              onClick={() => exportFilteredPin(row.p_id)}
              disabled={loading}
            >
              {loading ? "Exporting..." : "Export CSV"}
            </Button> */}
          </>
        </>
      ),
    },
  ];

  const export_pinDetailsColumns = [
    { label: "Member Name", key: "member_name" },
    { label: "Pin", key: "name" },
    { label: "Assign Date", key: "date" },
  ];
  const pinDetailColumns = [
    {
      name: "Member Name",
      selector: (row) => row?.member_name,
      sortable: true,
      // width: "70px",
    },
    {
      name: "Pin",
      selector: (row) => row?.name,
      sortable: true,
      width: "220px",
    },
    {
      name: "Assign Date",
      selector: (row) => row?.date,
      sortable: true,
      width: "170px",
    },
  ];
  return (
    <>
      <Page title="LVB Pin Badge | Local Vocal Business Group">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="h4" gutterBottom>
                  LVB Pin Badge
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => (toggleAddEditModal(), resetFormData())}
                style={{ marginBottom: "15px", float: "right" }}
                variant="contained"
              >
                {/* Add Pin */}
                {step == 1 ? "Add Pin" : "Back"}
              </Button>
            </Grid>
          </Grid>

          {step == 2 && (
            <>
              <Card spacing={3} style={{ padding: "10px" }}>
                <h3>Add New Pin / Badge</h3>
              </Card>
            </>
          )}
          <Modal
            open={addEditModal}
            onClose={() => (toggleAddEditModal(), resetFormData())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                Add New Pin
              </Typography>
              <hr />
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <Grid container>
                    <Grid item xs={12} sm={12} p={2}>
                      <h4
                        style={{
                          textAlign: "center",
                          marginBottom: "15px",
                        }}
                      >
                        Select Image
                      </h4>
                      <label className="pinImageLabel" htmlFor="file">
                        <div
                          className="selectImage"
                          style={{ textAlign: "center" }}
                        >
                          {pinData?.file?.length != 0 ? (
                            <img
                              src={
                                typeof pinData?.file == "object"
                                  ? URL.createObjectURL(pinData.file)
                                  : `${Routes.API_HOSTNAME}pin-image/${pinData?.file}`
                              }
                              alt="img"
                              className="selectedImage"
                              width="140px"
                              height="140px"
                              style={{ margin: "5px" }}
                            />
                          ) : (
                            <>
                              <ImageSearchRounded color="primary" />
                              <h5> Select Image </h5>
                            </>
                          )}
                        </div>

                        <input
                          id="file"
                          name="file"
                          type="file"
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) => {
                            setPinData({
                              ...pinData,
                              file: e.currentTarget.files[0],
                            });
                          }}
                        />
                      </label>
                      <Label
                        variant={"ghost"}
                        color={"error"}
                        style={{
                          margin: "10px auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "fit-content",
                          paddingBottom: "5px",
                          paddingTop: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Note : Image Size Must Be 512 * 512
                      </Label>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        style={{ marginTop: "15px" }}
                        height={10}
                        fullWidth
                        multiline
                        autoComplete="off"
                        type="text"
                        label="Pin Name"
                        name="name"
                        placeholder="Enter Pin Name"
                        onChange={(e) => {
                          setPinData({
                            ...pinData,
                            name: e.target.value,
                          });
                        }}
                        value={pinData?.name}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        style={{ marginTop: "15px" }}
                        height={10}
                        fullWidth
                        multiline
                        autoComplete="off"
                        type="number"
                        label="Pin Point"
                        name="name"
                        placeholder="Enter Pin Points"
                        onChange={(e) => {
                          setPinData({
                            ...pinData,
                            pin_point: e.target.value,
                          });
                        }}
                        value={pinData?.pin_point}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        style={{ marginTop: "15px" }}
                        height={10}
                        fullWidth
                        multiline
                        autoComplete="off"
                        type="text"
                        label="Pin Description"
                        name="description"
                        placeholder="Enter Pin Description"
                        onChange={(e) => {
                          setPinData({
                            ...pinData,
                            description: e.target.value
                          });
                        }}
                        value={pinData?.description}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => (toggleAddEditModal(), resetFormData())}
                        style={{ marginTop: "15px", marginRight: "15px" }}
                      >
                        Close
                      </Button>
                      <Button
                        variant="contained"
                        style={{ marginTop: "15px" }}
                        onClick={() => handlePinSubmit()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
              </Stack>
            </Box>
          </Modal>

          {/*  */}

          <Modal
            open={pinDataModal}
            onClose={() => (
              setCurrentPin(0),
              togglePinDataModal(),
              setFilter({
                chpt_id: 0,
                m_id: 0,
                mActive: -1,
                startDate: "",
                endDate: "",
              }),
              setExportFileName(""),
              setExportPinList([])
            )}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={pinAssignModalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                {exportFileName} Pin Assign Details
              </Typography>
              <hr />

              <Stack style={{ marginBottom: "10px", marginTop: "10px" }}>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={4}>
                    <Stack spacing={3}>
                      <label style={{ marginBottom: "10px" }}>
                        Member Active Type
                      </label>
                      <Select
                        value={
                          memberActiveType &&
                          memberActiveType.map((option) => {
                            if (option.value == filter.mActive) {
                              return {
                                value: option.value,
                                label: option.label,
                              };
                            }
                          })
                        }
                        onChange={(e) => {
                          setFilter({ ...filter, mActive: e.value });
                          activeAndDeActiveMemberFilter(e.value);
                        }}
                        options={
                          memberActiveType &&
                          memberActiveType.map((option) => {
                            return {
                              value: option.value,
                              label: option.label,
                            };
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    <Button className="btn btn-export">
                      <CSVLink
                        data={exportPinList}
                        headers={export_pinDetailsColumns}
                        filename={`${exportFileName} Pin Assign Details.csv`}
                        className="btn btn-primary test"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    </Button>
                  </Grid>
                </Grid>
                <Card>
                  <DataTable
                    filter
                    fixedHeaderScrollHeight="450px"
                    fixedHeader={true}
                    pagination
                    highlightOnHover
                    columns={pinDetailColumns}
                    data={exportPinList}
                  />
                </Card>
              </Stack>
              <hr />
              <div
                style={{
                  margin: "10px  auto 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => (
                    setCurrentPin(0),
                    setFilter({
                      chpt_id: 0,
                      m_id: 0,
                      mActive: -1,
                      startDate: "",
                      endDate: "",
                    }),
                    togglePinDataModal(),
                    setExportFileName(""),
                    setExportPinList([])
                  )}
                  style={{ marginTop: "0px", marginRight: "0px" }}
                >
                  Close
                </Button>
              </div>
            </Box>
          </Modal>

          <Card spacing={3} style={{ padding: "10px", marginTop: "20px" }}>
            {/* {tableData ? ( */}
            <Button className="btn btn-export" style={{ padding: "0" }}>
              <CSVLink
                data={tableData}
                headers={expo_columns}
                filename={"Pin Data.csv"}
                className="btn btn-primary test"
                target="_blank"
              >
                Export CSV
              </CSVLink>
            </Button>
            <DataTable
              filter
              pagination
              highlightOnHover
              columns={columns}
              data={tableData != null ? tableData : []}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
