import React, { useState, useEffect } from "react";

// material
import { Box, Card, Grid, Modal, Typography } from "@mui/material";
import { Stack, Container, TextField, Button, Tooltip, IconButton } from "@mui/material";
import Page from "../components/Page";
import { notification } from "src/utils/messages";
import { EditSharp, ImageSearchRounded } from "@mui/icons-material";
import "../Assets/css/style.css";
import DataTable from "react-data-table-component";
import { Routes } from "../constant/api_url";
import {
  insertAchievementpinBadge,
  updateAchievementpinBadge
} from "src/api/commonapi";
import { fetchAchievementPinList } from "src/utils/common";
import Label from "src/components/Label";

export default function AchievementPinBadge() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2
  };

  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const [step, setStep] = useState(1);
  const [addEditModal, setAddEditModal] = useState(false);
  const [pinData, setPinData] = useState({
    name: "",
    file: "",
    description: "",
    status: "1"
  });
  const [tableData, setTableData] = useState([]);
  const toggleAddEditModal = () => setAddEditModal(!addEditModal);

  const resetFormData = () => {
    setPinData({
      name: "",
      file: "",
      description: "",
      status: "1"
    });
  };
  const handlePinSubmit = async (newData = undefined) => {
    if (newData != undefined ? newData : pinData.a_p_id != undefined) {
      // update Existing Pin
      if (
        (newData != undefined ? newData : pinData).name != "" &&
        (newData != undefined ? newData : pinData).file != ""
      ) {
        let fnData = new FormData();
        fnData.append("a_p_id", newData != undefined ? newData.a_p_id : pinData.a_p_id);
        fnData.append("name", newData != undefined ? newData.name : pinData.name);
        fnData.append(
          "pin_sequence",
          newData != undefined ? newData.pin_sequence : pinData.pin_sequence
        );
        fnData.append("pig_image", newData != undefined ? newData.file : pinData.file);
        fnData.append("status", newData != undefined ? newData.status : pinData.status);
        fnData.append(
          "description",
          newData != undefined ? newData.description : pinData.description
        );

        let resp = await updateAchievementpinBadge(fnData);
        if (resp.status != 0) {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
          resetFormData();
          newData == undefined && toggleAddEditModal();
          getPinBadge();
        } else {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
        }
      } else {
        let notify = notification({
          type: "error",
          message: "Please Fill All Fields data"
        });
        notify();
      }
    } else {
      // Insert New Pin
      if (pinData.name != "" && pinData.file != "") {
        let fnData = new FormData();
        fnData.append("name", pinData.name);
        fnData.append("pig_image", pinData.file);
        fnData.append("status", pinData.status);
        fnData.append("pin_sequence", pinData.pin_sequence);
        fnData.append("description", pinData.description ? pinData.description : "");
        let resp = await insertAchievementpinBadge(fnData);
        if (resp.status != 0) {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
          resetFormData();
          toggleAddEditModal();
          getPinBadge();
        } else {
          let notify = notification({
            type: resp.status == 0 ? "error" : "success",
            message: resp.message
          });
          notify();
        }
      } else {
        let notify = notification({
          type: "error",
          message: "Please Fill All Fields data"
        });
        notify();
      }
    }
  };

  const getPinBadge = async () => {
    let res = await fetchAchievementPinList();
    setTableData(res);
  };
  useEffect(() => {
    getPinBadge();
  }, []);

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row?.a_p_id,
      sortable: true,
      width: "70px"
    },
    {
      name: "Name",
      wrap: true,
      selector: (row) => row?.name,
      sortable: true
    },
    {
      name: "Image",
      selector: (row) => (
        <>
          <img
            title={row.name}
            src={`${Routes.API_HOSTNAME}achievement-pin-image/${row?.image}`}
            width="90"
            alt={row.name}
          />
        </>
      ),
      sortable: true
    },
    {
      name: "Status",
      wrap: true,
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Label variant={"ghost"} color={row.status == 1 ? "success" : "error"}>
            {row.status == 1 ? "Active" : "Disabled"}
          </Label>

          <Button
            className="bnt-btn-primary"
            variant="contained"
            color={row.status == 1 ? "error" : "primary"}
            style={{ padding: "2px 10px", marginTop: "5px" }}
            onClick={async (e) => {
              let newData = {
                name: row.name,
                a_p_id: row.a_p_id,
                pin_sequence: row.pin_sequence,
                description: row.description,
                file: row.image,
                status: row.status == 1 ? 0 : 1
              };
              await handlePinSubmit(newData);
            }}
          >
            {row.status == 1 ? "Disabled" : " Active"}
          </Button>
        </div>
      ),
      sortable: true
    },
    {
      name: "Pin Description",
      selector: (row) => row?.description,
      wrap: true,
      sortable: true
    },
    {
      name: "Sequence",
      selector: (row) => row?.pin_sequence,
      sortable: true,
      width: "100px"
    },
    {
      name: "Action",
      wrap: true,
      sortable: true,
      width: "100px",
      selector: (row) => (
        <>
          <Tooltip title="Edit Pin">
            <IconButton
              onClick={() => {
                setPinData({
                  name: row.name,
                  a_p_id: row.a_p_id,
                  pin_sequence: row.pin_sequence,
                  file: row.image,
                  status: row.status,
                  description: row.description
                });
                toggleAddEditModal();
              }}
            >
              <EditSharp color="warning" />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ];

  return (
    <>
      <Page title="Achievement Pin Badge | Local Vocal Business Group">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h4" gutterBottom>
                  Achievement Pin Badge
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  const lastSequence =
                    tableData?.length > 0
                      ? Math.max(...tableData.map((item) => item.pin_sequence || 0))
                      : 0;
                  setPinData({
                    name: "",
                    file: "",
                    pin_sequence: lastSequence + 1, // Auto-incremented sequence
                    description: "",
                    status: 1
                  });
                  toggleAddEditModal();
                }}
                style={{ marginBottom: "15px", float: "right" }}
                variant="contained"
              >
                {/* Add Pin */}
                {step == 1 ? "Add Pin" : "Back"}
              </Button>
            </Grid>
          </Grid>

          {step == 2 && (
            <>
              <Card spacing={3} style={{ padding: "10px" }}>
                <h3>Add New Pin / Badge</h3>
              </Card>
            </>
          )}
          <Modal
            open={addEditModal}
            onClose={() => (toggleAddEditModal(), resetFormData())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                Add New Pin
              </Typography>
              <hr />
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <Grid container>
                  <Grid item xs={12} sm={12} p={2}>
                    <h4
                      style={{
                        textAlign: "center",
                        marginBottom: "15px"
                      }}
                    >
                      Select Image
                    </h4>
                    <label className="pinImageLabel" htmlFor="file">
                      <div className="selectImage" style={{ textAlign: "center" }}>
                        {pinData?.file?.length != 0 ? (
                          <img
                            src={
                              typeof pinData?.file == "object"
                                ? URL.createObjectURL(pinData.file)
                                : `${Routes.API_HOSTNAME}achievement-pin-image/${pinData?.file}`
                            }
                            alt="img"
                            className="selectedImage"
                            width="140px"
                            height="140px"
                            style={{ margin: "5px" }}
                          />
                        ) : (
                          <>
                            <ImageSearchRounded color="primary" />
                            <h5> Select Image </h5>
                          </>
                        )}
                      </div>

                      <input
                        id="file"
                        name="file"
                        type="file"
                        hidden
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => {
                          setPinData({
                            ...pinData,
                            file: e.currentTarget.files[0]
                          });
                        }}
                      />
                    </label>
                    <Label
                      variant={"ghost"}
                      color={"error"}
                      style={{
                        margin: "10px auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "fit-content",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                        fontSize: "14px"
                      }}
                    >
                      Note : Image Size Must Be 512 * 512
                    </Label>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      style={{ marginTop: "15px" }}
                      height={10}
                      fullWidth
                      multiline
                      autoComplete="off"
                      type="text"
                      label="Pin Name"
                      name="name"
                      placeholder="Enter Pin Name"
                      onChange={(e) => {
                        setPinData({
                          ...pinData,
                          name: e.target.value
                        });
                      }}
                      value={pinData?.name}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      style={{ marginTop: "15px" }}
                      height={10}
                      fullWidth
                      autoComplete="off"
                      type="number"
                      label="Pin Sequence"
                      name="name"
                      placeholder="Enter Pin Sequence"
                      onChange={(e) => {
                        setPinData({
                          ...pinData,
                          pin_sequence: e.target.value
                        });
                      }}
                      value={pinData?.pin_sequence}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      style={{ marginTop: "15px" }}
                      height={10}
                      fullWidth
                      multiline
                      autoComplete="off"
                      type="text"
                      label="Pin Description"
                      name="description"
                      placeholder="Enter Pin Description"
                      onChange={(e) => {
                        setPinData({
                          ...pinData,
                          description: e.target.value
                        });
                      }}
                      value={pinData?.description}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => (toggleAddEditModal(), resetFormData())}
                      style={{ marginTop: "15px", marginRight: "15px" }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginTop: "15px" }}
                      onClick={() => handlePinSubmit()}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Modal>

          {/*  */}

          <Card spacing={3} style={{ padding: "10px", marginTop: "20px" }}>
            <DataTable
              filter
              pagination
              highlightOnHover
              columns={columns}
              data={tableData != null ? tableData : []}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
