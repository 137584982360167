import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import Page from "src/components/Page";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  insertMembershipFlow,
  membershipFlowImageDelete,
  updateMembershipFlow
} from "src/api/commonapi";
import { notification } from "src/utils/messages";
import DataTable from "react-data-table-component";
import { DeleteSharp, EditSharp } from "@mui/icons-material";
import { Routes } from "../constant/api_url";
import { fetchMembershipFlowList } from "src/utils/common";
import Deletemodal from "./DeleteModal";

export default function MembershipFlow() {
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [response, setresponse] = useState(0);
  const [addEditModal, setAddEditModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMembershipFlowId, setSelectedMembershipFlowId] = useState(null);
  const [membershipFlowImagesData, setMembershipFlowImagesData] = useState({
    files: []
  });
  const [tableData, setTableData] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isEditMode ? "30%" : "60%",
    maxHeight: "60vh",
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 2,
    overflowY: "auto"
  };

  const toggleAddEditModal = () => setAddEditModal(!addEditModal);

  const resetFormData = () => {
    setMembershipFlowImagesData({
      files: []
    });
    setIsEditMode(false);
  };

  const handleMemberhsipFlowSubmit = async () => {
    if (membershipFlowImagesData.files && membershipFlowImagesData.files.length > 0) {
      const formData = new FormData();
      let response;

      try {
        if (isEditMode) {
          // Update with a single image
          formData.append("image", membershipFlowImagesData.files[0]);
          formData.append("m_f_id", selectedMembershipFlowId);
          response = await updateMembershipFlow(formData);
        } else {
          // Insert all selected images
          membershipFlowImagesData.files.forEach((file) => {
            console.log("membershipFlowImagesData.files", file);
            formData.append("images[]", file); // Use consistent key for multiple images
          });
          response = await insertMembershipFlow(formData);
        }

        const notify = notification({
          type: response.status === 0 ? "error" : "success",
          message: response.message
        });
        notify();

        if (response.status !== 0) {
          resetFormData();
          toggleAddEditModal();
          getMemberhsipFlowImages();
        }
      } catch (error) {
        notification({
          type: "error",
          message: "Error uploading images. Please try again."
        })();
        console.error("Upload Error:", error);
      }
    } else {
      notification({
        type: "error",
        message: "Please select images before submitting."
      })();
    }
  };

  const flowimagedelete = async (m_f_id) => {
    const resp = await membershipFlowImageDelete(m_f_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message
    });
    notify();

    if (resp.status == 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
    getMemberhsipFlowImages();
  };

  const getMemberhsipFlowImages = async () => {
    let res = await fetchMembershipFlowList();
    setTableData(res);
  };

  useEffect(() => {
    getMemberhsipFlowImages();
  }, []);

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row) => row?.m_f_id,
      sortable: true,
      width: "70px"
    },
    {
      name: "Image",
      selector: (row) => (
        <>
          <img
            title={row.image}
            src={`${Routes.API_HOSTNAME}membershipflow-image/${row?.image}`}
            width="90"
            alt={row.image}
          />
        </>
      ),
      sortable: false
    },
    {
      name: "Action",
      wrap: true,
      sortable: true,
      width: "170px",
      selector: (row) => (
        <>
          <>
            <Tooltip title="Edit Image">
              <IconButton
                onClick={() => {
                  setMembershipFlowImagesData({
                    files: [`${Routes.API_HOSTNAME}membershipflow-image/${row.image}`]
                  });
                  setSelectedMembershipFlowId(row.m_f_id); // Store the ID for update
                  setIsEditMode(true);
                  toggleAddEditModal();
                }}
              >
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </>
          <>
            <a
              onClick={() => {
                setDvisible({ mval: true, id: row.m_f_id });
              }}
            >
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteSharp color="error" />
                </IconButton>
              </Tooltip>
            </a>
          </>
        </>
      )
    }
  ];

  return (
    <>
      <Deletemodal visible={dvisible} setVisible={setDvisible} deletefunc={flowimagedelete} />
      <Page title="Membership Flow | Local Vocal Business Group">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h4" gutterBottom>
                  Membership Flow
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  toggleAddEditModal();
                  resetFormData();
                }}
                style={{ marginBottom: "15px", float: "right" }}
                variant="contained"
              >
                Add Membership Flow Images
              </Button>
            </Grid>
          </Grid>

          <Modal
            open={addEditModal}
            onClose={() => {
              toggleAddEditModal();
              resetFormData();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                {isEditMode ? "Edit Membership Flow Image" : "Add New Membership Flow Images"}
              </Typography>
              <hr />
              <Stack spacing={10} style={{ marginBottom: "15px" }}>
                <Grid container>
                  <Grid item xs={12} sm={12} p={2}>
                    <div className="selectImage" style={{ textAlign: "center" }}>
                      {membershipFlowImagesData?.files?.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                            justifyContent: "center"
                          }}
                        >
                          {membershipFlowImagesData.files.map((file, index) => (
                            <div
                              key={index}
                              style={{ position: "relative", display: "inline-block" }}
                            >
                              <img
                                src={
                                  typeof file === "string"
                                    ? file
                                    : file instanceof File
                                    ? URL.createObjectURL(file)
                                    : ""
                                }
                                alt={`Selected ${index + 1}`}
                                width="140px"
                                height="140px"
                                style={{
                                  border: "1px solid #ddd",
                                  borderRadius: "10px"
                                }}
                              />
                              {!isEditMode && (
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    backgroundColor: "red",
                                    color: "white"
                                  }}
                                  onClick={() => {
                                    const updatedFiles = membershipFlowImagesData.files.filter(
                                      (_, i) => i !== index
                                    );
                                    setMembershipFlowImagesData({
                                      ...membershipFlowImagesData,
                                      files: updatedFiles
                                    });
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h5>No Images Selected</h5>
                      )}

                      <Button variant="contained" component="label" style={{ marginTop: "20px" }}>
                        {isEditMode ? "Select an Image" : "Click to Upload Images"}
                        <input
                          id="file"
                          name="file"
                          type="file"
                          hidden
                          multiple={!isEditMode} // Multiple selection only allowed in Add mode
                          accept="image/*"
                          onChange={(e) => {
                            const selectedFiles = Array.from(e.target.files);
                            const maxSize = 2 * 1024 * 1024; // 2MB in bytes

                            if (isEditMode) {
                              const selectedFile = selectedFiles[0];

                              if (selectedFile) {
                                if (selectedFile.size > maxSize) {
                                  let notify = notification({
                                    type: "error",
                                    message: `${selectedFile.name} exceeds 2MB limit.`
                                  });
                                  notify();
                                  e.target.value = "";
                                  return;
                                }

                                setMembershipFlowImagesData({
                                  ...membershipFlowImagesData,
                                  files: [selectedFile]
                                });
                              }
                            } else {
                              if (selectedFiles.length === 0) {
                                setMembershipFlowImagesData({
                                  ...membershipFlowImagesData,
                                  files: []
                                });
                                return;
                              }

                              const validFiles = selectedFiles.filter((file) => {
                                if (file.size > maxSize) {
                                  let notify = notification({
                                    type: "error",
                                    message: `${file.name} exceeds 2MB limit.`
                                  });
                                  notify();
                                  return false;
                                }
                                return true;
                              });

                              if (validFiles.length === 0) {
                                let notify = notification({
                                  type: "error",
                                  message: "Please select images under 2MB."
                                });
                                notify();
                                e.target.value = "";
                                setMembershipFlowImagesData({
                                  ...membershipFlowImagesData,
                                  files: []
                                });
                                return;
                              }

                              setMembershipFlowImagesData({
                                ...membershipFlowImagesData,
                                // files: Array.from(e.target.files)
                                files: validFiles
                              });
                            }
                          }}
                          className="form-control"
                        />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Stack>

              {/* Fixed Button Container */}
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  marginTop: "15px"
                }}
              >
                <Button variant="outlined" onClick={() => (toggleAddEditModal(), resetFormData())}>
                  Close
                </Button>
                <Button variant="contained" onClick={handleMemberhsipFlowSubmit}>
                  Submit
                </Button>
              </Grid>
            </Box>
          </Modal>

          <Card spacing={3} style={{ padding: "10px", marginTop: "20px" }}>
            <DataTable
              filter
              pagination
              highlightOnHover
              columns={columns}
              data={tableData != null ? tableData : []}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
