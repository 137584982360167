import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchV2vList,
  fetchChaptList,
  fetchReferenceList,
  fetchchapterbyuser,
  fetchReferenceListByMember,
  fetchReferenceByMember,
  fetchallcitylist,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import {
  deleteBusiness,
  deleteReference,
  GetMemberByChptId,
} from "src/api/commonapi";
import { DeleteSharp, Filter } from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Label from "src/components/Label";

export default function ReferenceReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [referenceData, setReferenceData] = useState([]);
  const [nreferenceData, setnreferenceData] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [filter, setFilter] = useState({
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    chpt_id: 0,
    m_id: 0,
    startDate: "",
    endDate: "",
    selectType: 0,
    startDate: "",
    crossType: 0,
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const filterData = () => {
    let nref = [];
    nref =
      referenceData &&
      referenceData.filter((ref) => {
        if (
          (filter.selectType == 1 && ref.r_to_id == filter.m_id) ||
          (filter.selectType == 2 && ref.r_by_id == filter.m_id) ||
          filter.selectType == 0
        ) {
          if (filter.startDate != "" && filter.endDate != "") {
            if (
              moment(ref.r_date, "DD-MM-YYYY").isBetween(
                filter.startDate,
                filter.endDate,
                "days",
                "[]"
              )
            ) {
              let res = handleCrossFilter(ref);
              return res;
            }
          } else {
            let res = handleCrossFilter(ref);
            return res;
          }
        }
      });
    setnreferenceData(nref);
  };

  const handleCrossFilter = (ref) => {
    if (filter.crossType == 1 || filter.crossType == 2) {
      if (ref.inOutChapter == filter.crossType) {
        return ref;
      }
    } else {
      return ref;
    }
  };

  useEffect(async () => {
    await filterData();
  }, [referenceData, filter]);

  const getChapterById = async (ch_id) => {
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getbusinesslist = async (ch_id, m_id) => {
    let res = await await fetchReferenceByMember(ch_id, m_id);

    let newData = res.map((reference) => {
      if (reference.r_to_id == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(reference.r_by_id)
          ? { ...reference, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : { ...reference, inOutChapter: 2, outChapterLabel: "Cross-Chapter" };
      } else if (reference.r_by_id == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(reference.r_to_id)
          ? { ...reference, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : { ...reference, inOutChapter: 2, outChapterLabel: "Cross-Chapter" };
      }
    });
    setReferenceData(newData);
  };

  useEffect(async () => {
    getchptlist();
  }, []);

  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "Reference Giver", key: "r_by_name" },
    { label: "Reference Receiver", key: "r_to_name" },
    { label: "Date", key: "r_date" },
    { label: "Reference Name", key: "r_name" },
    { label: "Contact No", key: "r_mobile_no" },
    { label: "Note", key: "r_note" },
    { label: "Rating", key: "r_rating" },
    { label: "In/Out Chapter", key: "outChapterLabel" },
  ];

  const columns = [
    {
      name: "No",
      wrap: true,
      selector: (row, key) => row.row_no,
      sortable: true,
      width: "80px",
    },
    {
      name: "Reference Giver",
      wrap: true,
      selector: (row) => row.r_by_name,
      sortable: true,
    },
    {
      name: "Reference Receiver",
      wrap: true,
      selector: (row) => row.r_to_name,
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      selector: (row) => row.r_date,
      sortable: true,
    },
    {
      name: "Reference Name",
      wrap: true,
      selector: (row) => row.r_name,
      sortable: true,
    },
    {
      name: "Contact No",
      wrap: true,
      selector: (row) => row.r_mobile_no,
      sortable: true,
    },
    {
      name: "Note",
      wrap: true,
      selector: (row) => row.r_note,
      sortable: true,
    },
    {
      name: "Rating",
      wrap: true,
      selector: (row) => row.r_rating,
      sortable: true,
      width: "100px",
    },
    {
      name: "In/Out Chapter",
      wrap: true,
      selector: (row) => (
        <div>
          <Label
            variant={"ghost"}
            color={row.inOutChapter == 1 ? "success" : "info"}
          >
            {row.outChapterLabel}
          </Label>
        </div>
      ),
    },
  ];
  const [filterTypeOptions, setFilterTypeOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "Reference Receiver" },
    { value: 2, label: "Reference Giver" },
  ]);

  const [inOutChapterOptions, setInOutChapterOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "In-Chapter" },
    { value: 2, label: "Cross-Chapter" },
  ]);

  const changeChapterHandler = async (e) => {
    setFilter({ ...filter, chpt_id: e.value, m_id: 0 });
    setnreferenceData([]);
    await getChapterById(e.value);
  };

  const [citylist, setcitylist] = useState([]);

  const [chaptlist, setchaplist] = useState([]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
    setchaplist(
      await fetchchapterbyuser(loginUser?.city_id, loginUser?.u_type)
    );
  };
  useEffect(async () => {
    getallcitylist();
  }, []);
  return (
    <>
      <Page title="Reference Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Reference Report
            </Typography>
          </Stack>

          <Card className="p-20">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select City</label>
                  <Select
                    menuPortalTarget={document.body}
                    isDisabled={
                      loginUser?.u_type == 1 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id != 1 &&
                        loginUser?.management_p == 1)
                        ? true
                        : false
                    }
                    options={[
                      {
                        value: 0,
                        label: "All City",
                      },
                      ...citylist.map((city) => {
                        return {
                          label: city?.city_name,
                          value: city?.city_id,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setFilter({ ...filter, city_id: e.value, chpt_id: 0 });
                    }}
                    value={
                      filter.city_id == 0
                        ? {
                            value: 0,
                            label: "All City",
                          }
                        : citylist.map((city) => {
                            return (
                              filter.city_id == city.city_id && {
                                value: city.city_id,
                                label: city.city_name,
                              }
                            );
                          })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Chapter</label>
                  <Select
                    name="chpt_id"
                    menuPortalTarget={document.body}
                    onChange={changeChapterHandler}
                    options={[
                      ...(chaptlist &&
                        chaptlist
                          ?.map((chpt) => {
                            if (filter?.city_id != 0) {
                              if (filter?.city_id == chpt?.city_id) {
                                return {
                                  value: chpt.chpt_id,
                                  label: chpt.chpt_name,
                                };
                              }
                            } else {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          })
                          .filter((f) => f != undefined)),
                    ]}
                    value={
                      chaptlist &&
                      chaptlist.map((c) => {
                        if (c.chpt_id == filter.chpt_id) {
                          return {
                            value: c.chpt_id,
                            label: c.chpt_name,
                          };
                        }
                      })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Member</label>
                  <Select
                   menuPortalTarget={document.body}
                    value={memberdata.map((member) => {
                      if (member.m_id == filter.m_id) {
                        return { value: member.m_id, label: member.m_name };
                      }
                    })}
                    onChange={(e) => {
                      setFilter({ ...filter, m_id: e.value });
                      getbusinesslist(filter.chpt_id, e.value);
                    }}
                    options={
                      memberdata &&
                      memberdata.map((option) => {
                        return { value: option.m_id, label: option.m_name };
                      })
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>Select Type</label>
                  <Select
                   menuPortalTarget={document.body}
                    value={filterTypeOptions.map((c) => {
                      if (c.value == filter.selectType) {
                        return { value: c.value, label: c.label };
                      }
                    })}
                    onChange={async (e) => {
                      await setFilter({ ...filter, selectType: e.value });
                    }}
                    options={filterTypeOptions}
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <label>In / Cross Chapter</label>
                  <Select
                   menuPortalTarget={document.body}
                    value={inOutChapterOptions.map((c) => {
                      if (c.value == filter.crossType) {
                        return { value: c.value, label: c.label };
                      }
                    })}
                    onChange={async (e) => {
                      setFilter({ ...filter, crossType: e.value });
                    }}
                    options={inOutChapterOptions}
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      type="date"
                      name="startdate"
                      label="Start Date "
                      value={filter?.startDate != "" ? filter?.startDate : null}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilter({
                          ...filter,
                          startDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      type="date"
                      name="enddate"
                      label="End Date "
                      value={filter?.endDate != "" ? filter?.endDate : null}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilter({
                          ...filter,
                          endDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      filterData(filter?.startDate, filter?.endDate);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilter({
                        chpt_id: 0,
                        m_id: 0,
                        startDate: "",
                        endDate: "",
                        selectType: 0,
                        crossType: 0,
                      });
                      setReferenceData([]);
                      setnreferenceData([]);
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    Clear
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
          <Card className="p-20" sx={{ mt: 2 }}>
            {nreferenceData && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={nreferenceData}
                    headers={expo_columns}
                    filename={"ReferenceData.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={nreferenceData}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
