import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useTable } from "react-table";
import {
  DeleteSharp,
  CalendarMonth,
  EditSharp,
  PlaylistAddCheckCircleIcon,
  Delete,
} from "@mui/icons-material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Tooltip,
  Box,
  IconButton,
} from "@mui/material";
// components
import Page from "../components/Page";
import Select from "react-select";
import { deletePoll, getpollresult, insertpoll } from "src/api/commonapi";
// StateData
import { PollSelect, fetchPollList } from "src/utils/common";
import { UpdateSetting, getSetting } from "src/api/updateSetting";
import { notification } from "src/utils/messages";
import Modal from "@mui/material/Modal";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
// ----------------------------------------------------------------------

export default function Poll() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const [getdata, setgetdata] = useState({
    p_type: loginUser?.u_type == 0 ? 0 : 2,
    s_date: null,
    e_date: null,
  });
  const [myModal, setMyModal] = useState(false);
  const [option, setOption] = useState([]);
  const [answer, setanswer] = useState([]);
  const [response, setresponse] = useState(0);
  const [polldata, setpolldata] = useState([]);
  const [pollResult, setPollResult] = useState({});
  const [answerUser, setAnswerUser] = useState(0);
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  // Modal Style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 4,
  };
  useEffect(async () => {
    setpolldata(await fetchPollList(loginUser.city_id, loginUser.u_type));
  }, [response]);

  useEffect(async () => {
    if (loginUser?.u_type == 1) {
      setSelectData(2);
    }
  }, []);
  // Delete Poll
  const deletepoll = async (p_id) => {
    const resp = await deletePoll(p_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      setresponse(response + 1);
      setDvisible({ mval: false, id: 0 });
    }
  };
  // Get Poll Result
  const getPollResult = async (p_id) => {
    const resp = await getpollresult(p_id);
    if (resp.status != 0) {
      setPollResult(resp);
    } else {
    }
  };
  // Cancel Modal
  const cancelModal = () => {
    setMyModal(false);
  };

  const expo_columns = [
    { label: "No", key: "p_id" },
    { label: "Poll Question", key: "p_question" },
    { label: "Poll Type", key: "p_type" },
    { label: "Start Date", key: "s_date" },
    { label: "End Date", key: "e_date" },
  ];

  const columns = [
    {
      name: "No",
      selector: (row) => row.p_id,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => row.s_date,
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => row.e_date,
      sortable: true,
    },
    {
      name: "Poll Question",
      selector: (row) => row.p_question,
    },
    {
      name: "Poll Type",
      selector: (row) =>
        row.p_type == 1
          ? "State"
          : row.p_type == 2
          ? "City"
          : row.p_type == 3
          ? "Chapter"
          : "LVB",
    },
    {
      name: "Location",
      selector: (row) =>
        row.p_type == 1
          ? row.s_name
          : row.p_type == 2
          ? row.city_name
          : row.p_type == 3
          ? row.ch_name
          : "",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <a
            onClick={() => {
              setDvisible({ mval: true, id: row.p_id });
            }}
          >
            <Tooltip title="Delete">
              <IconButton>
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </a>
          <a
            onClick={() => {
              getPollResult(row.p_id);
              setMyModal(true);
            }}
          >
            <Tooltip title="View Result">
              <IconButton>
                <PlaylistAddCheckIcon color="success" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ];
  const onChangeFunction = (e) => {
    if (e.target.name == "p_type") {
      setOption([]);
      setSelectData(e.target.value);
    }
    setgetdata({
      ...getdata,
      [e.target.name]: e.target.value,
    });
  };
  const setSelectData = async (type = 0) => {
    let optiondata = [];
    if (type != 0) {
      const resp = await PollSelect(
        type,
        loginUser?.u_type,
        loginUser?.city_id
      );
      resp?.map((e) => optiondata.push({ value: e.value, label: e.name }));
      setOption([{ value: 0, label: "Select" }, ...optiondata]);
    }
  };
  const addanswer = () => {
    setanswer([
      ...answer,
      {
        value: "",
      },
    ]);
  };
  const handleSubmit = async () => {
    let fdata = new FormData();
    fdata.append("p_type", getdata.p_type);
    fdata.append("p_type_id", getdata.p_type == 0 ? 0 : getdata.p_type_id);
    fdata.append("s_date", moment(getdata.s_date).format("DD-MM-YYYY"));
    fdata.append("e_date", moment(getdata.e_date).format("DD-MM-YYYY"));
    fdata.append("p_question", getdata.question);
    Object.keys(answer).map((key) => {
      fdata.append("p_answer", answer[key].value);
    });
    if (
      (getdata.p_type != 0 &&
        (getdata.p_type_id == 0 || getdata.p_type_id == undefined)) ||
      getdata.question == "" ||
      getdata.question == undefined
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill Required Fields",
      });
      notify();
    } else {
      if (
        answer.length == 0 ||
        (answer.length > 0 && answer.some((e) => e.value == ""))
      ) {
        let notify = notification({
          type: "error",
          message: "Please Enter Answers",
        });
        notify();
      } else {
        const resp = await insertpoll(fdata);
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();

        setgetdata({ p_type: loginUser?.u_type == 0 ? 0 : 2 });
        setanswer([]);

        setresponse(response + 1);
      }
    }
  };

  const handleValueChange = (index, newValue) => {
    const updatedAnswer = [...answer];
    updatedAnswer[index].value = newValue;
    setanswer(updatedAnswer);
  };

  const deleteObject = (index) => {
    const newArray = answer.filter((_, i) => index != i);
    setanswer(newArray);
  };

  const export_readPollDetailColumns = [
    { label: "Person Name", key: "m_name" },
    { label: "Chapter Name", key: "chpt_name" }
  ];
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletepoll}
      />
      <Modal
        open={myModal}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Poll Result
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "280px", overflowY: "scroll" }}>
              <div className="Poll_details">
                <div
                  className="question"
                  style={{
                    padding: "5px 0",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <b> Poll Question &nbsp;</b> : {pollResult.question}
                </div>
                <div
                  className="ans_counter"
                  style={{
                    padding: "5px 0",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <b> Total Answer &nbsp;</b> : {pollResult.total_vote}
                </div>
              </div>

              <div className="poll_question">
                <b> Poll Answers &nbsp;</b> <br />
                {pollResult.option?.map((e) => {
                  var pre =
                    Math.round(
                      ((e.vote_counter * 100) / pollResult.total_vote +
                        Number.EPSILON) *
                        100
                    ) / 100;

                  return (
                    <>
                      <div
                        className="answerList"
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          paddingBottom: "10px",
                          marginBottom: "10px",
                        }}
                        onClick={() => {
                          setAnswerUser(e.p_c_id);
                        }}
                      >
                        <div className="answer" style={{ marginRight: "20px" }}>
                          {e.p_c_option}
                        </div>
                        <div
                          style={{
                            width: "50%",
                            border: "1px solid #00ab55",
                            borderRadius: "20px",
                          }}
                        >
                          <div
                            style={{
                              width: pre + "%",
                              backgroundColor: "#00ab55",
                              color: "#fff",
                              padding: "0 10px ",
                              borderRadius: "20px",
                            }}
                          >
                            {e.vote_counter}
                          </div>
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                          <p style={{ fontSize: "13px" }}>{pre + "%"}</p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "start",
                          marginBottom: "20px"
                        }}
                      >
                        <div
                          className="answerUser"
                          style={
                            answerUser != 0 && answerUser == e.p_c_id
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <table
                            style={{
                              border: "1px solid #000",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #000",
                                  borderCollapse: "collapse",
                                  padding: "5px",
                                }}
                              >
                                Persone Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #000",
                                  borderCollapse: "collapse",
                                  padding: "5px",
                                }}
                              >
                                Chapter Name
                              </td>
                            </tr>
                            {pollResult.userlist?.map((us) => {
                              if (us.p_c_id == e.p_c_id) {
                                return (
                                  <>
                                    <tr>
                                      <td
                                        style={{
                                          border: "1px solid #000",
                                          borderCollapse: "collapse",
                                          padding: "5px",
                                        }}
                                      >
                                        {us.m_name}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #000",
                                          borderCollapse: "collapse",
                                          padding: "5px",
                                        }}
                                      >
                                        {us.chpt_name}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            })}
                          </table>
                        </div>
                        {answerUser != 0 &&
                          answerUser == e.p_c_id &&
                          pollResult.userlist?.filter((us) => us.p_c_id == e.p_c_id).length > 0 && (
                            <Button className="btn btn-export" style={{ marginLeft: "10px" }}>
                              <CSVLink
                                data={pollResult.userlist?.filter((us) => us.p_c_id == e.p_c_id)}
                                headers={export_readPollDetailColumns}
                                filename={`Poll Result.csv`}
                                className="btn btn-primary test"
                                target="_blank"
                              >
                                Export CSV
                              </CSVLink>
                            </Button>
                          )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" onClick={cancelModal}>
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Page title="Poll | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="h4" gutterBottom>
              Poll
            </Typography>
          </Stack>
          {/* Start State Form */}
          <Card style={{ padding: "25px", marginBottom: "25px" }}>
            {/* End State Form */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{ marginBottom: "0px" }}
                  >
                    Poll Display
                  </FormLabel>
                  <RadioGroup
                    style={{ marginTop: "0px" }}
                    row
                    defaultValue={0}
                    value={getdata.p_type}
                    onChange={(e) => {
                      onChangeFunction(e);
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="p_type"
                  >
                    {loginUser?.u_type == 0 && (
                      <>
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="LVB"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="State"
                        />
                      </>
                    )}
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="City"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="Chapter"
                    />
                  </RadioGroup>
                </Stack>
              </Grid>
              {getdata.p_type != 0 ? (
                <Grid item xs={12}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <label style={{ marginBottom: "10px" }}>
                      Select
                      {getdata.p_type == 1
                        ? " State"
                        : getdata.p_type == 2
                        ? " City"
                        : getdata.p_type == 3
                        ? " Chapter"
                        : " LVB"}
                    </label>
                    <Select
                      required={true}
                      onChange={(e) => {
                        setgetdata({ ...getdata, p_type_id: e.value });
                      }}
                      menuPortalTarget={document.body}
                      options={option}
                    />
                  </Stack>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      name="s_date"
                      label="Start Date "
                      inputFormat="dd/MM/yyyy"
                      value={getdata.s_date != null ? getdata.s_date : null}
                      onChange={(newDate) => {
                        setgetdata({
                          ...getdata,
                          s_date: newDate,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      name="e_date"
                      label="End Date "
                      inputFormat="dd/MM/yyyy"
                      value={getdata.e_date != null ? getdata.e_date : null}
                      onChange={(newDate) => {
                        setgetdata({
                          ...getdata,
                          e_date: newDate,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // onKeyDown={(e) => e.preventDefault()}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    required={true}
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Question"
                    name="question"
                    id="outlined-required"
                    onChange={onChangeFunction}
                    value={getdata?.question ? getdata?.question : ""}
                    error={getdata.question == "" ? true : false}
                    helperText={
                      getdata.question == "" ? "Please Enter Poll Question" : ""
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {answer &&
                  answer?.map((ans, i) => {
                    return (
                      <>
                        <Stack spacing={3} style={{ marginBottom: "15px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={11} style={{ paddingLeft: "0px" }}>
                              <TextField
                                style={{ marginTop: "0px" }}
                                fullWidth
                                autoComplete="off"
                                type="text"
                                label="Answer"
                                // name="answer"
                                id="outlined-required"
                                onChange={(e) =>
                                  handleValueChange(i, e.target.value)
                                }
                                value={ans.value}
                                error={ans?.value == null ? true : false}
                                helperText={
                                  ans.value == null
                                    ? "Please Enter Poll Answer"
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className="d-flex align-item-center"
                            >
                              <IconButton
                                color="error"
                                aria-label="delete"
                                onClick={() => deleteObject(i)}
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Stack>
                      </>
                    );
                  })}
                <Button
                  onClick={addanswer}
                  style={{ marginTop: "15px" }}
                  variant="contained"
                >
                  Add Answer
                </Button>
              </Grid>
            </Grid>
            <Button
              onClick={handleSubmit}
              style={{ marginTop: "15px" }}
              variant="contained"
            >
              Add Poll
            </Button>
          </Card>
          <Card>
            {polldata && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={polldata}
                    headers={expo_columns}
                    filename={"PollData.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  highlightOnHover
                  pagination
                  columns={columns}
                  data={polldata != null && polldata}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
